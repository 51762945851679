import React, { useState, useEffect, useContext } from 'react';
import { Select, Input, notification, PageHeader } from 'antd';
import styles from '../css/CandidateForm.module.css';
import {
  getpopularCities,
  getClientNames,
  getJobLocations,
  basicFormSubmit,
} from '../service/SingleCandidateFormService';
import { useHistory } from 'react-router-dom';
import * as queryString from 'query-string';
import { AppContext } from '../App';

const AddSingleCandidateForm = ({ showHeading = true }) => {
  const history = useHistory();
  const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
  const parsedUrl = queryString.parse(window.location.search);
  let basicInfoFormPrefilledObject = {
    cityPreference: parsedUrl?.city,
    desiredRole: parsedUrl?.category,
    clientPreference: parsedUrl?.client,
    firstLocalityPreference: parsedUrl?.locality || null,
  };

  const [candidateName, setCandidateName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState('');
  const [cityPreference, setCityPreference] = useState(
    basicInfoFormPrefilledObject?.cityPreference || null
  );
  const [clientPreference, setClientPreference] = useState(
    basicInfoFormPrefilledObject?.clientPreference || null
  );
  const [locationPreference, setLocationPreference] = useState(
    basicInfoFormPrefilledObject?.firstLocalityPreference || null
  );
  const [cityOptions, setCityOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  // const [phoneError, setPhoneError] = useState(false);
  // const [candidateNameError, setCandidateNameError] = useState(false);
  const [showAlternatePhoneNumber, setShowAlternatePhoneNumber] = useState(false);
  const [fieldError, setFieldError] = useState('');
  const [labelErrorMessage, setLabelErrorMessage] = useState("");

  const loadCityPreferences = async () => {
    setIsLoadingCities(true);
    try {
      getpopularCities().then((response) => {
        setCityOptions(
          response?.data?.data?.premiumCities?.map((city) => ({ label: city, value: city }))
        );
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to load city preferences. Please try again.',
      });
    } finally {
      setIsLoadingCities(false);
    }
  };

  useEffect(() => {
    loadCityPreferences();
  }, []);

  const loadClientPreferences = async () => {
    setIsLoadingClients(true);
    try {
      getClientNames(cityPreference).then((response) => {
        setClientOptions(response.data.data.clientNames);
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to load preferences. Please try again.',
      });
    } finally {
      setIsLoadingClients(false);
    }
  };

  useEffect(() => {
    if (cityPreference) {
      loadClientPreferences();
    }
  }, [cityPreference]);

  const loadLocationPreferences = async () => {
    setIsLoadingLocations(true);
    try {
      getJobLocations(cityPreference, clientPreference).then((response) => {
        setLocationOptions(
          response.data.data.jobLocations?.map((location) => ({
            label: location,
            value: location,
          }))
        );
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to load preferences. Please try again.',
      });
    } finally {
      setIsLoadingLocations(false);
    }
  };

  useEffect(() => {
    if (clientPreference) {
      loadLocationPreferences();
    }
  }, [clientPreference]);

  const onFinish = async () => {

    // CANDIDATE NAME VALIDATION
    if(candidateName.trim().length === 0 || !/^[a-zA-Z\s]+$/.test(candidateName.trim()) || candidateName.length < 3){
      setFieldError('candidateName');
      setLabelErrorMessage("Please enter the name with no special characters and minimum length 4")
      return;
    } else {
      setFieldError('');
      setLabelErrorMessage('');
    }

    // PHONE NUMBER VALIDATION
    if (
      phoneNumber.trim().length === 0 || 
      phoneNumber.trim().length !== 10 || 
      isNaN(phoneNumber) || 
      !/^[6-9]\d{9}$/.test(phoneNumber.trim()) 
    ) {
      setFieldError('phoneNumber');
      setLabelErrorMessage(
        "Please enter a valid 10-digit mobile number starting with 6, 7, 8, or 9, without +91 or 0 as prefix."
      );
      return;
    } else {
      setFieldError('');
      setLabelErrorMessage('');
    }

    // ALTERNATE PHONE NUMBER VALIDATION
    if(
      showAlternatePhoneNumber && (
      alternatePhoneNumber.trim().length === 0 || 
      alternatePhoneNumber.trim().length !== 10 || 
      isNaN(alternatePhoneNumber) 
      || !/^[6-9]\d{9}$/.test(alternatePhoneNumber.trim()) 
    )){
      setFieldError('alternatePhoneNumber');
      setLabelErrorMessage("Please enter a valid 10-digit mobile number starting with 6, 7, 8, or 9, without +91 or 0 as prefix.")
      return;
    } else {
      setFieldError('');
      setLabelErrorMessage('');
    }

    // Validate Candidate City Preference
    if (!cityPreference) {
      notification.error({
        message: 'Lead City Preference not selected',
        description: 'Please select a city preference.',
      });
      return;
    }

    // Validate Client Preference
    if (!clientPreference) {
      notification.error({
        message: 'Client Preference not selected',
        description: 'Please select a client preference.',
      });
      return;
    }

    // Validate Candidate Location Preference
    if (!locationPreference) {
      notification.error({
        message: 'Lead Location Preference not selected',
        description: 'Please select a location preference.',
      });
      return;
    }

    // If all validations pass, proceed with form submission
    const payload = {
      candidateName,
      phoneNumber,
      sourceId: mitraReducer?.mitraInfo?.id || '',
      clientName: clientPreference || '',
      mitraPhoneNumber: mitraReducer?.mitraInfo?.phoneNumber,
      team: JSON.stringify(
        mitraReducer?.mitraInfo?.teamId ? [mitraReducer?.mitraInfo?.teamId] : []
      ),
      city: cityPreference || '',
      locality: locationPreference || '',
    };

    try {
      const response = await basicFormSubmit(payload);

      if (response?.data?.data?.uniquenessStatus) {
        if (response?.data?.data?.additionalFormAvailable) {
          const leadObj = {
            companyName: clientPreference,
            companyCity: cityPreference,
            addAlternativePhoneNumber:
              alternatePhoneNumber && alternatePhoneNumber.length > 0 ? true : false,
            alternatePhoneNumber: alternatePhoneNumber,
            desiredRole: 'Delivery',
            firstLocalityPreference: locationPreference,
            name: candidateName,
            submit: true,
            phoneNumber: phoneNumber,
            candidatePhoneNumber: phoneNumber,
            sourceName: 'mitra-leader',
            managerMitraID: mitraReducer?.mitraInfo?.managerMitraID,
            jobId: response?.data?.data?.result?.jobId,
            jobDemandId: response?.data?.data?.result?.jobDemandId,
            recommendationHistoryId: response?.data?.data?.result?.recommendationHistoryId,
          };

          if (leadObj?.companyName?.toLowerCase().indexOf('zomato') !== -1) {
            // If the client is zomato and has an additional form then
            return history.push({
              pathname: '/zomato-additional-form',
              route: 'jobRecommendationPage',
              search: new URLSearchParams(leadObj).toString(),
            });
          } else if (leadObj?.companyName?.toLowerCase() === 'blinkit') {
            // If the client is zomato and has an additional form then
            return history.push({
              pathname: '/blinkit-additional-form',
              route: 'jobRecommendationPage',
              search: new URLSearchParams(leadObj).toString(),
            });
          } else if (leadObj?.companyName?.toLowerCase() === 'blinkit darkstore') {
            return history.push({
              pathname: '/blinkit-darkstore-additional-form',
              route: 'jobRecommendationPage',
              search: new URLSearchParams(leadObj).toString(),
            });
          } else if (leadObj?.companyName?.toLowerCase().indexOf('swiggy') !== -1) {
            // If the client is swiggy and has an additional form then
            // return history.push({pathname: '/swiggy-additional-form',
            // route: 'jobRecommendationPage',
            // search:new URLSearchParams(leadObj).toString()});
            return history.replace({ pathname: '/leads/all-candidates' });
          } else if (leadObj?.companyName?.toLowerCase() === 'zepto') {
            // If the client is zepto and has an additional form then
            return history.push({
              pathname: '/zepto-additional-form',
              route: 'jobRecommendationPage',
              search: new URLSearchParams(leadObj).toString(),
            });
          } else if (leadObj?.companyName?.toLowerCase() === 'swiggy soc') {
            return history.push({
              pathname: '/swiggy-soc-additional-form',
              route: 'jobRecommendationPage',
              search: new URLSearchParams(leadObj).toString(),
            });
          } else if (leadObj?.companyName?.toLowerCase() === 'shadowfax') {
            return history.replace({ pathname: '/leads/all-candidates' });
          } else {
            // For clients other than zomato
            return history.push({
              pathname: '/moreInfo',
              route: 'jobRecommendationPage',
              search: new URLSearchParams(leadObj).toString(),
            });
          }
        } else {
          notification.success({
            message: 'Form submitted successfully',
            description: '',
          });
          history.push('/leads/all-candidates');
        }
      } else {
        notification.error({
          message: 'Error in referring the lead',
          description: response?.data?.data?.uniquenessMessage,
        });
      }
    } catch (err) {
      console.warn(err);
      notification.error({
        message: 'referral failed',
        description: 'Please try again after some time',
      });
    }
  };

  return (
    <div>
      {showHeading && <PageHeader className="site-page-header" title="Add Lead" />}

      <div
        style={{
          textAlign: 'left',
          margin: '30px 20px',
          fontWeight: 'bold',
        }}
      >
        Add lead
      </div>

      <div className={styles.candidateForm} style={{ fontFamily: 'var(--primary-font-family)' }}>
        <div className={styles.header}>
          <h1>Basic Info</h1>
        </div>

        <div className={styles.content}>
          {/* NAME */}
          <div className={styles.field}>
            <label htmlFor="leadName">
              Lead Name <sup className={styles.required}>*</sup>
            </label>
            <Input
              id="leadName"
              placeholder="Enter Lead Name"
              size="large"
              value={candidateName}
              onChange={(e) => setCandidateName(e.target.value)}
              style={{
                borderColor: fieldError === 'candidateName' ? 'red' : undefined,
              }}
            />
            {fieldError === 'candidateName' && (
              <div style={{ color: 'red', fontSize: '12px', marginLeft: '0.1rem' }}>
                {labelErrorMessage}
              </div>
            )}
          </div>

          {/*PHONE NUMBER */}
          <div className={styles.field}>
            <label htmlFor="leadPhoneNumber">
              Lead Phone Number <sup className={styles.required}>*</sup>
            </label>
            <Input
              id="leadPhoneNumber"
              placeholder="Enter Lead Phone Number"
              size="large"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              style={{
                borderColor: fieldError === 'phoneNumber' ? 'red' : undefined,
              }}
              maxLength={10}
            />
            {fieldError === 'phoneNumber' && (
              <div style={{ color: 'red', fontSize: '12px', marginLeft: '0.1rem' }}>
                {labelErrorMessage}
              </div>
            )}
          </div>

          {/* ALTERNATE PHONE NUMBER */}
          {!showAlternatePhoneNumber && (
            <div
              className={styles.field}
              style={{
                color: '#ff6b00',
                marginLeft: '2rem',
                fontWeight: '500',
                cursor: 'pointer',
                fontSize: '11.2px',
                marginTop: '3rem',
                marginBottom: '6rem',
              }}
              onClick={() => setShowAlternatePhoneNumber(!showAlternatePhoneNumber)}
            >
              + ADD ALTERNATIVE PHONE NUMBER
            </div>
          )}

          {showAlternatePhoneNumber && (
            <div className={styles.field}>
              <label htmlFor="leadAlternatePhoneNumber">Lead Alternate Phone Number</label>
              <Input
                id="leadAlternatePhoneNumber"
                placeholder="Enter Lead Alternate Phone Number"
                size="large"
                value={alternatePhoneNumber}
                onChange={(e) => setAlternatePhoneNumber(e.target.value)}
                style={{
                  borderColor: fieldError === 'alternatePhoneNumber' ? 'red' : undefined,
                }}
                maxLength={10}
              />
              {fieldError === 'alternatePhoneNumber' && (
                <div style={{ color: 'red', fontSize: '12px', marginLeft: '0.1rem' }}>
                  {labelErrorMessage}
                </div>
              )}
            </div>
          )}

          {/* CITY PREFERENCE */}
          <div className={styles.field}>
            <label htmlFor="cityPreference">
              Lead City Preference <sup className={styles.required}>*</sup>
            </label>
            <Select
              id="cityPreference"
              placeholder="Select Lead City Preference"
              size="large"
              value={cityPreference}
              onChange={(value) => {
                setCityPreference(value);
                setClientPreference(null);
                setLocationPreference(null);
              }}
              style={{ width: '100%', fontSize: '14px' }}
              options={cityOptions}
              loading={isLoadingCities}
              showSearch
              dropdownStyle={{ textAlign: 'left' }}
            />
          </div>

          {/* CLIENT PREFERENCE */}
          <div className={styles.field}>
            <label htmlFor="clientPreference">
              Client Preference <sup className={styles.required}>*</sup>
            </label>
            <Select
              id="clientPreference"
              placeholder="Choose Preferred Client"
              size="large"
              value={clientPreference}
              onChange={(value) => {
                setClientPreference(value);
              }}
              options={clientOptions}
              loading={isLoadingClients}
              disabled={!cityPreference}
              style={{ width: '100%', fontSize: '14px' }}
              dropdownStyle={{ textAlign: 'left' }}
            />
          </div>

          {/* LOCATION PREFERENCE */}
          <div className={styles.field}>
            <label htmlFor="locationPreference">
              Lead Locality Preference <sup className={styles.required}>*</sup>
            </label>
            <Select
              id="locationPreference"
              placeholder="Lead Location Preference"
              size="large"
              value={locationPreference}
              onChange={(value) => {
                setLocationPreference(value);
              }}
              options={locationOptions}
              loading={isLoadingLocations}
              disabled={!cityPreference || !clientPreference}
              style={{ width: '100%', fontSize: '14px' }}
              showSearch
              dropdownStyle={{ textAlign: 'left' }}
            />
          </div>
        </div>

        <div className={styles.footer}>
          <button
            type="button" // `type="button"` is appropriate here, as `type="primary"` isn't valid HTML
            size="large"
            onClick={onFinish}
            disabled={
              candidateName === '' ||
              phoneNumber === '' ||
              !cityPreference ||
              !clientPreference ||
              !locationPreference
            }
            style={{
              backgroundColor:
                candidateName === '' ||
                phoneNumber === '' ||
                !cityPreference ||
                !clientPreference ||
                !locationPreference
                  ? '#6fa0ff'
                  : '#0d6efd',
              color: '#fff', // Optional: Add text color for better contrast
              border: 'none', // Optional: Remove border for consistency with the background color
              cursor:
                candidateName === '' ||
                phoneNumber === '' ||
                !cityPreference ||
                !clientPreference ||
                !locationPreference
                  ? 'not-allowed'
                  : 'pointer',
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSingleCandidateForm;
