import React, { Fragment, useContext, useState } from 'react'
import SamvaadiniAdvertisement from './components/SamvaadiniAdvertisement';
import { Divider, notification } from 'antd';
import SelectScript from './file-upload-flow/SelectScript';
import SummaryScreen from './file-upload-flow/SummaryScreen';
import Thunder from '../../static/images/thunder.gif';
import RequiredCandidatesForm from './smart-select-flow/RequiredCandidatesForm';
import LeftArrow from '../../static/svg/LeftArrow';
import { useHistory } from 'react-router-dom';
import { getSamvaadiniSummaryInformation, postBulkIntentDataForSamvadiniCalls, postSamvaadiniSession } from '../../service/SamvadiniApiService';
import { AppContext } from '../../App';
import StepsIndicatorDesktop from './components/StepsIndicatorDesktop';
import StepsIndicatorMobile from './components/StepsIndicatorMobile';

function SamvaadiniSmartLogicContainer() {
    const [showAd, setShowAd] = useState(false);
    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);
    const [requiredCandidateCount, setRequiredCandidateCount] = useState(null);
    const [pitchClient, setPitchClient] = useState(null);
    const [summaryInfo, setSummaryInfo] = useState(null);
    const submissionType = 'Smart Select';
    const [loadingSummaryInfo, setLoadingSummaryInfo] = useState(false);
    const [loader, setLoader] = useState(false);
    const [steps, setSteps] = useState([
        {
            id: 0,
            label: 'Select Leads',
            key: 'select',
            isActive: true, // to determine the active layout
            isCompleted: false, // to determine if step is already completer
        },
        {
            id: 1,
            label: 'Select Script',
            key: 'script',
            isActive: false,
            isCompleted: false
        },
        {
            id: 2,
            label: 'Preview',
            key: 'preview',
            isActive: false,
            isCompleted: false
        }
    ]);

    const [currentStep, setCurrentStep] = useState('select');

    const handleStepClick = (stepInfo) => {
        if(!stepInfo?.isCompleted && !stepInfo.isActive) return;

        const modifiedSteps = [...steps];

        for (let step = 0; step < modifiedSteps.length; step++) {
            if(modifiedSteps[step].id === stepInfo.id) {
                setCurrentStep(modifiedSteps[step].key);
            }
        }

        setSteps([...modifiedSteps]);
    }

    const goToNextScreen = (nextStepKey) => {
        const modifiedSteps = [...steps];

        let isNextStepMarkedActive = false; // using this to mark previous steps as completed

        for (let step = 0; step < modifiedSteps.length; step++) {
            if(modifiedSteps[step].key === nextStepKey) {
                setCurrentStep(modifiedSteps[step].key);
                modifiedSteps[step].isActive = true;
                isNextStepMarkedActive = true;
            } else {
                modifiedSteps[step].isActive = false;

                if(!modifiedSteps[step].isCompleted && !isNextStepMarkedActive) {
                    // marking previous step as completed if its not already marked as completed
                    // the condition means that we havent found the next step yet and prev step is also marked as incomplete,
                    // hence marking prev step as completed.
                    modifiedSteps[step].isCompleted = true;
                }

                // Dont need to check for other conditions like
                // once marked the next step as active, other steps after that would be incomplete by default
            }
        }

        setSteps([...modifiedSteps]);
    }

    const submitForm = async() => {
        console.log(requiredCandidateCount, pitchClient, 'Smart Logic');

        setLoader(true); 

        try {

            const formData = new FormData();
            formData.append('expectedLeadCount', requiredCandidateCount);
            formData.append('type', submissionType);
            formData.append('mitraId', mitraReducer?.mitraInfo?.id)
            formData.append('pitchClient', pitchClient)
            formData.append('isManagerMitra', mitraReducer?.mitraInfo?.managerMitraID ? false: true)

            const response = await postSamvaadiniSession(formData);

            if(response?.data?.status) {
                if(response?.data?.warning) {
                    notification['warning']({
                        message: response?.data?.warning
                    });
                } else {
                    notification['success']({
                        message: 'Details submitted.'
                    });
                }

                if(response?.data?.intentCallingSessionId) {
                    const param = {
                        intentCallingSessionId:response?.data?.intentCallingSessionId
                    }
                    history.push(`/samvadini-session-details?sessionDetails=${JSON.stringify(param)}`);
                }

                setLoader(false);

            } else {
                setLoader(false);
                notification['error']({
                    message: response?.data?.message || 'Error in submitting the response.'
                });

            }
        } catch (e) {
            setLoader(false);
            notification['error']({
                message: 'Something went wrong, please try again.'
            });
        }
    }

    const getSummaryInfo = async()=> {
        console.log(requiredCandidateCount, pitchClient, 'Smart Logic');
        console.log('getting summary info');

        setLoadingSummaryInfo(true);

        try {
            const formData = new FormData();
            formData.append('expectedLeadCount', requiredCandidateCount);
            formData.append('type', submissionType);
            formData.append('mitraId', mitraReducer?.mitraInfo?.id)
            formData.append('pitchClient', pitchClient)
            formData.append('isManagerMitra', mitraReducer?.mitraInfo?.managerMitraID ? false: true)

            const preIcsInfo = await getSamvaadiniSummaryInformation(formData);

            if(preIcsInfo?.data) {
                setSummaryInfo(preIcsInfo?.data);
            }

            if(preIcsInfo?.data?.isCapped) { // max per session
                notification['warning']({
                    message: `Candidates are capped at ${preIcsInfo?.data?.cappedValue || '2000'} per session.`
                })
            }
            else if(preIcsInfo?.data?.dailyLimitReached) { // max per day
                notification['warning']({
                    message: preIcsInfo?.data?.dailyLimitReachedMessage
                })
            } 

            setLoadingSummaryInfo(false);
        } catch(err) {
            setLoadingSummaryInfo(false);
            console.log(err);
        }
        
    }

    const resetSteps = () => {
        setSteps([
            {
                id: 0,
                label: 'Select Leads',
                key: 'select',
                isActive: true, // to determine the active layout
                isCompleted: false, // to determine if step is already completer
            },
            {
                id: 1,
                label: 'Select Script',
                key: 'script',
                isActive: false,
                isCompleted: false
            },
            {
                id: 2,
                label: 'Preview',
                key: 'preview',
                isActive: false,
                isCompleted: false
            }
        ]);
    }

  return (
    <div className='gray-main-container'>
        <div style={{marginBottom: '32px'}} className="back-navigation d-flex align-items-center" onClick={()=>history.push('/samvadini-v2')}>
            <LeftArrow />
            <span className='back-text'>Back</span>
        </div>
        {/* Advertisement markup */}
        <SamvaadiniAdvertisement isExpanded={false} header='Smart Logic' icon={Thunder}/>

        {/* Markup to show steps bar */}
        <div className="file-upload-steps-container">

            {
                steps.map((step, index) => (
                    <StepsIndicatorDesktop step={step} handleStepClick={handleStepClick} key={step?.id}/>
                ))
            }
        </div>

        <div className="file-upload-steps-container-mobile" style={{margin:"24px"}}>

            {
                steps.map((step, index) => (
                    <StepsIndicatorMobile index={index} step={step} key={index} steps={steps} handleStepClick={handleStepClick}/>
                ))
            }
        </div>


        <div className="session-creation-header inter-medium-14-20" style={{margin: '48px 32px 0px', textTransform:'capitalize'}}>{currentStep}</div>
        {/* <Divider style={{margin: '24px auto', width: '90%', minWidth: '90%'}}/> */}

        {
            currentStep === 'select' ? (
            <RequiredCandidatesForm 
            goToNextScreen={goToNextScreen} 
            requiredCandidateCount={requiredCandidateCount} 
            resetSteps={resetSteps}
            mitraReducer={mitraReducer}
            setRequiredCandidateCount={setRequiredCandidateCount}/>): null
        }

        {
            currentStep === 'script' ? <SelectScript  goToNextScreen={goToNextScreen} setPitchClient={setPitchClient}/>: null
        }

        {
            currentStep === 'preview' ? <SummaryScreen 
            submitForm={submitForm} 
            summaryInfo={summaryInfo} 
            getSummaryInfo={getSummaryInfo} 
            pitchClient={pitchClient}
            loadingSummaryInfo={loadingSummaryInfo}
            loader={loader}
            submissionType={submissionType}/>: null
        }
            
    </div>
  )
}

export default SamvaadiniSmartLogicContainer