import React, { useContext, useState } from 'react'
import SamvaadiniAdvertisement from './components/SamvaadiniAdvertisement';
import SettingGif from '../../static/images/adv-filter.gif';
import { Divider, notification } from 'antd';
import SelectScript from './file-upload-flow/SelectScript';
import SummaryScreen from './file-upload-flow/SummaryScreen';
import FilterSelectionScreen from './advance-filter-flow/FilterSelectionScreen';
import LeftArrow from '../../static/svg/LeftArrow';
import { useHistory } from 'react-router-dom';
import { getSamvaadiniSummaryInformation, postSamvaadiniSession } from '../../service/SamvadiniApiService';
import { AppContext } from '../../App';
import moment from 'moment';
import StepsIndicatorDesktop from './components/StepsIndicatorDesktop';
import StepsIndicatorMobile from './components/StepsIndicatorMobile';

function SamvaadiniAdvanceFilterContainer() {
    const [showAd, setShowAd] = useState(false);
    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);

    const [pitchClient, setPitchClient] = useState(null);
    const [summaryInfo, setSummaryInfo] = useState(null);
    const submissionType = 'Advance Filter';
    const [loadingSummaryInfo, setLoadingSummaryInfo] = useState(true);

    const [loader, setLoader] = useState(false);

    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const [startDateParent, setStartDateParent] = useState('');
	const [endDateParent, setEndDateParent] = useState('');

    const [steps, setSteps] = useState([
        {
            id: 0,
            label: 'Select Filters',
            key: 'filters',
            isActive: true, // to determine the active layout
            isCompleted: false, // to determine if step is already completer
        },
        {
            id: 1,
            label: 'Select Script',
            key: 'script',
            isActive: false,
            isCompleted: false
        },
        {
            id: 2,
            label: 'Preview',
            key: 'preview',
            isActive: false,
            isCompleted: false
        }
    ]);

    const [currentStep, setCurrentStep] = useState('filters');

    const handleStepClick = (stepInfo) => {
        if(!stepInfo?.isCompleted && !stepInfo.isActive) return;

        const modifiedSteps = [...steps];

        for (let step = 0; step < modifiedSteps.length; step++) {
            if(modifiedSteps[step].id === stepInfo.id) {
                setCurrentStep(modifiedSteps[step].key);
            }
        }

        setSteps([...modifiedSteps]);
    }

    const goToNextScreen = (nextStepKey) => {
        const modifiedSteps = [...steps];

        let isNextStepMarkedActive = false; // using this to mark previous steps as completed

        for (let step = 0; step < modifiedSteps.length; step++) {
            if(modifiedSteps[step].key === nextStepKey) {
                setCurrentStep(modifiedSteps[step].key);
                modifiedSteps[step].isActive = true;
                isNextStepMarkedActive = true;
            } else {
                modifiedSteps[step].isActive = false;

                if(!modifiedSteps[step].isCompleted && !isNextStepMarkedActive) {
                    // marking previous step as completed if its not already marked as completed
                    // the condition means that we havent found the next step yet and prev step is also marked as incomplete,
                    // hence marking prev step as completed.
                    modifiedSteps[step].isCompleted = true;
                }

                // Dont need to check for other conditions like
                // once marked the next step as active, other steps after that would be incomplete by default
            }
        }

        setSteps([...modifiedSteps]);
    }

    const submitForm = async() => {

        const filterData = {}

        if(startDateParent && endDateParent) {
            filterData.startDate = moment(startDateParent).format('YYYY-MM-DD');
            filterData.endDate = moment(endDateParent).format('YYYY-MM-DD');
        }

        if(selectedCities.length) {
            const cityList = [];

            for (let i = 0; i < selectedCities.length; i++) {
                cityList.push(selectedCities[i].label.toLowerCase());
            }

            filterData.cities = [...cityList];
        }

        if(selectedClients.length) {
            const clientList = [];

            for (let i = 0; i < selectedClients.length; i++) {
                clientList.push(selectedClients[i].label.toLowerCase());
            }

            filterData.clients = [...clientList];
        }

        if(selectedTeamMembers.length) {
            const teamList = [];

            for (let i = 0; i < selectedTeamMembers.length; i++) {
                teamList.push(selectedTeamMembers[i].id);
            }

            filterData.mitraIds = [...teamList];
        }

        try {

            setLoader(true);

            const formData = new FormData();
            formData.append('type', submissionType);
            formData.append('mitraId', mitraReducer?.mitraInfo?.id);
            formData.append('isManagerMitra', mitraReducer?.mitraInfo?.managerMitraID ? false: true)
            if(pitchClient) {
                formData.append('pitchClient', pitchClient);
            }
            formData.append('filters', JSON.stringify(filterData));

            const response = await postSamvaadiniSession(formData);

            if(response?.data?.status) {
                if(response?.data?.warning) {
                    notification['warning']({
                        message: response?.data?.warning
                    });
                } else {
                    notification['success']({
                        message: 'Details submitted.'
                    });
                }

                if(response?.data?.intentCallingSessionId) {
                    const param = {
                        intentCallingSessionId:response?.data?.intentCallingSessionId
                    }
                    history.push(`/samvadini-session-details?sessionDetails=${JSON.stringify(param)}`);
                }

                setLoader(false);

            } else {
                setLoader(false);
                notification['error']({
                    message: response?.data?.message || 'Error in submitting the response.'
                });

            }
        } catch (e) {
            setLoader(false);
            notification['error']({
                message: 'Something went wrong, please try again.'
            });
        }
    }

    const getSummaryInfo = async()=> {

        setLoadingSummaryInfo(true);
        console.log(startDateParent, endDateParent, selectedCities,selectedClients,selectedTeamMembers, pitchClient);

        const filterData = {}

        if(startDateParent && endDateParent) {
            filterData.startDate = moment(startDateParent).format('YYYY-MM-DD');
            filterData.endDate = moment(endDateParent).format('YYYY-MM-DD');
        }

        if(selectedCities.length) {
            const cityList = [];

            for (let i = 0; i < selectedCities.length; i++) {
                cityList.push(selectedCities[i].label.toLowerCase());
            }

            filterData.cities = [...cityList];
        }

        if(selectedClients.length) {
            const clientList = [];

            for (let i = 0; i < selectedClients.length; i++) {
                clientList.push(selectedClients[i].label.toLowerCase());
            }

            filterData.clients = [...clientList];
        }

        if(selectedTeamMembers.length) {
            const teamList = [];

            for (let i = 0; i < selectedTeamMembers.length; i++) {
                teamList.push(selectedTeamMembers[i].id);
            }

            filterData.mitraIds = [...teamList];
        }

        try {

            console.log(startDateParent, endDateParent, submissionType, mitraReducer?.mitraInfo?.id,pitchClient,filterData, 'ADV FILTER');

            const formData = new FormData();
            formData.append('type', submissionType);
            formData.append('mitraId', mitraReducer?.mitraInfo?.id);
            formData.append('isManagerMitra', mitraReducer?.mitraInfo?.managerMitraID ? false: true)
            if(pitchClient) {
                formData.append('pitchClient', pitchClient);
            }
            formData.append('filters', JSON.stringify(filterData));

            const preIcsInfo = await getSamvaadiniSummaryInformation(formData);

            if(preIcsInfo?.data) {
                setSummaryInfo(preIcsInfo?.data);
            }

            if(preIcsInfo?.data?.isCapped) { // max per session
                notification['warning']({
                    message: `Candidates are capped at ${preIcsInfo?.data?.cappedValue || '2000'} per session.`
                })
            }
            else if(preIcsInfo?.data?.dailyLimitReached) { // max per day
                notification['warning']({
                    message: preIcsInfo?.data?.dailyLimitReachedMessage
                })
            }   

            setLoadingSummaryInfo(false);
        } catch(err) {
            if(!err?.message?.includes?.('Operation canceled due to new request')) {
                setLoadingSummaryInfo(false);
            }
            console.log(err);
        }
        
    }
  return (
    <div className='gray-main-container'>
        <div style={{marginBottom: '32px'}} className="back-navigation d-flex align-items-center" onClick={()=>history.push('/samvadini-v2')}>
            <LeftArrow />
            <span className='back-text'>Back</span>
        </div>

        {/* Advertisement markup */}
        <SamvaadiniAdvertisement isExpanded={false} header='Advance Filter' icon={SettingGif}/>

        {/* Markup to show steps bar */}
        <div className="file-upload-steps-container">

            {
                steps.map((step, index) => (
                    <StepsIndicatorDesktop step={step} handleStepClick={handleStepClick} key={step?.id}/>
                ))
            }
        </div>

        <div className="file-upload-steps-container-mobile" style={{margin:"24px"}}>

            {
                steps.map((step, index) => (
                    <StepsIndicatorMobile step={step} index={index} key={index} steps={steps} handleStepClick={handleStepClick}/>
                ))
            }
        </div>


        <div className="session-creation-header inter-medium-14-20" style={{margin: '48px 32px 0px', textTransform:'capitalize'}}>{currentStep === 'filters' ? 'Select': currentStep}</div>
        <Divider style={{width: '90%', minWidth: '90%', margin: '24px auto'}}/>

        {
            currentStep === 'filters' ? <FilterSelectionScreen 
            selectedCities={selectedCities}
            setSelectedCities={setSelectedCities}
            goToNextScreen={goToNextScreen} 
            selectedClients={selectedClients}
            setSelectedClients={setSelectedClients}
            selectedTeamMembers={selectedTeamMembers}
            setSelectedTeamMembers={setSelectedTeamMembers}
            setStartDateParent={setStartDateParent}
            setEndDateParent={setEndDateParent}
            getCandidateCount={getSummaryInfo}
            summaryInfo={summaryInfo}
            loadingSummaryInfo={loadingSummaryInfo}
            />: null
        }

        {
            currentStep === 'script' ? <SelectScript  goToNextScreen={goToNextScreen} setPitchClient={setPitchClient}/>:null
        }

        {
            currentStep === 'preview' ? <SummaryScreen 
            submitForm={submitForm} 
            summaryInfo={summaryInfo}
            getSummaryInfo={getSummaryInfo} 
            pitchClient={pitchClient}
            loadingSummaryInfo={loadingSummaryInfo}
            submissionType={submissionType}
            loader={loader}
            />: null
        }
    </div>
  )
}

export default SamvaadiniAdvanceFilterContainer