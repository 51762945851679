import React, { useContext, useEffect, useState } from 'react';
import './styles/SamvaadiniFileUpload.css';

import { Avatar, Button, Divider, Input, notification } from 'antd';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import WavingHand from '../../static/images/wave.gif';
import FileUpload from './file-upload-flow/FileUpload';
import SelectScript from './file-upload-flow/SelectScript';
import SelectTelecallers from './file-upload-flow/SelectTelecallers';
import SummaryScreen from './file-upload-flow/SummaryScreen';
import SamvaadiniAdvertisement from './components/SamvaadiniAdvertisement';
import Thumbsup from '../../static/images/excel-upload.gif';
import LeftArrow from '../../static/svg/LeftArrow';
import { AppContext } from '../../App';
import { getSamvaadiniSummaryInformation, postSamvaadiniSession } from '../../service/SamvadiniApiService';
import StepsIndicatorDesktop from './components/StepsIndicatorDesktop';
import StepsIndicatorMobile from './components/StepsIndicatorMobile';
import { getMitraTeamMembersList } from '../../service/commonService';

function SamvaadiniFileUploadContainer() {
    const history = useHistory();
    const [showAd, setShowAd] = useState(false);
    const { mitraReducer } = useContext(AppContext);
    const [file, setFile] = useState(null);
    const [pitchClient, setPitchClient] = useState(null);
    const [summaryInfo, setSummaryInfo] = useState(null);

    const [loader, setLoader] = useState(false);
    const submissionType = 'File Upload';
    const [loadingSummaryInfo, setLoadingSummaryInfo] = useState(false);
    const [isNonReferral, setIsNonReferral] = useState(false);
    const [steps, setSteps] = useState([
        {
            id: 0,
            label: 'Upload File',
            subLabel: 'Upload',
            mobileLabel: 'Upload File',
            key: 'upload',
            isActive: true, // to determine the active layout
            isCompleted: false, // to determine if step is already completer
        },
        {
            id: 1,
            label: 'Select Script',
            subLabel: 'Script',
            mobileLabel: 'Select Script',
            key: 'script',
            isActive: false,
            isCompleted: false
        },
        {
            id: 2,
            label: 'Preview',
            subLabel: 'Preview',
            mobileLabel: 'Preview',
            key: 'preview',
            isActive: false,
            isCompleted: false
        }
    ]);
    const [nonReferralSteps, setNonReferralSteps] = useState([
        {
            id: 0,
            label: 'Upload File',
            subLabel: 'Upload',
            mobileLabel: 'Upload File',
            key: 'upload',
            isActive: true, // to determine the active layout
            isCompleted: false, // to determine if step is already completer
        },
        {
            id: 1,
            label: 'Select Script',
            subLabel: 'Script',
            mobileLabel: 'Select Script',
            key: 'script',
            isActive: false,
            isCompleted: false
        },
        {
            id: 2,
            label: 'Select Team Members',
            subLabel: 'Telecallers',
            mobileLabel: 'Select Members',
            key: 'assignTC',
            isActive: false,
            isCompleted: false,
        },
        {
            id: 3,
            label: 'Preview',
            subLabel: 'Preview',
            mobileLabel: 'Preview',
            key: 'preview',
            isActive: false,
            isCompleted: false
        }
    ]);
    const [eligibleSteps, setEligibleSteps] = useState([])

    const [currentStep, setCurrentStep] = useState('upload');
    const [currentStepSubLabel, setCurrentStepSubLabel] = useState('upload');
    const [mitraTeamList, setMitraTeamList] = useState([]);
    const [selectedTCList, setSelectedTCList] = useState([])

    useEffect(() => {
        setEligibleSteps(steps)
    }, [])

    useEffect(() => {
        if (isNonReferral)
            setEligibleSteps(nonReferralSteps)
        else
            setEligibleSteps(steps)
    }, [isNonReferral])

    const showNonReferralFlow = (val) => {
        setIsNonReferral(val)
    }

    const handleStepClick = (stepInfo) => {
        if (!stepInfo?.isCompleted && !stepInfo.isActive) return;

        const modifiedSteps = [...eligibleSteps];

        for (let step = 0; step < modifiedSteps.length; step++) {
            if (modifiedSteps[step].id === stepInfo.id) {
                setCurrentStep(modifiedSteps[step].key);
                setCurrentStepSubLabel(modifiedSteps[step].subLabel)
            }
        }

        setEligibleSteps([...modifiedSteps]);
    }

    const goToNextScreen = (nextStepKey) => {
        const modifiedSteps = [...eligibleSteps];

        let isNextStepMarkedActive = false; // using this to mark previous steps as completed

        for (let step = 0; step < modifiedSteps.length; step++) {
            if (modifiedSteps[step].key === nextStepKey) {
                setCurrentStep(modifiedSteps[step].key);
                setCurrentStepSubLabel(modifiedSteps[step].subLabel)
                modifiedSteps[step].isActive = true;
                isNextStepMarkedActive = true;
            } else {
                modifiedSteps[step].isActive = false;

                if (!modifiedSteps[step].isCompleted && !isNextStepMarkedActive) {
                    // marking previous step as completed if its not already marked as completed
                    // the condition means that we havent found the next step yet and prev step is also marked as incomplete,
                    // hence marking prev step as completed.
                    modifiedSteps[step].isCompleted = true;
                }

                // Dont need to check for other conditions like
                // once marked the next step as active, other steps after that would be incomplete by default
            }
        }

        setEligibleSteps([...modifiedSteps]);
    }

    const onTCListSelected = (tcList) => {
        setSelectedTCList(tcList)
    }

    const submitForm = async () => {
        console.log(file, pitchClient, 'File Upload');

        try {
            setLoader(true);
            const formData = new FormData();
            formData.append('type', submissionType);
            formData.append('mitraId', mitraReducer?.mitraInfo?.id)
            formData.append('pitchClient', pitchClient);
            formData.append('files', file);
            formData.append('isManagerMitra', mitraReducer?.mitraInfo?.managerMitraID ? false : true)
            if (isNonReferral)
                formData.append('mitraListSelected', JSON.stringify(selectedTCList));

            const response = await postSamvaadiniSession(formData);

            if (response?.data?.status) {
                if (response?.data?.warning) {
                    notification['warning']({
                        message: response?.data?.warning
                    });
                } else {
                    notification['success']({
                        message: 'Details submitted.'
                    });
                }

                if (response?.data?.intentCallingSessionId) {
                    const param = {
                        intentCallingSessionId: response?.data?.intentCallingSessionId
                    }
                    history.push(`/samvadini-session-details?sessionDetails=${JSON.stringify(param)}`);
                }

                setLoader(false);

            } else {
                setLoader(false);
                notification['error']({
                    message: response?.data?.message || 'Error in submitting the response.'
                });

            }
        } catch (e) {
            setLoader(false);
            notification['error']({
                message: 'Something went wrong, please try again.'
            });
        }
    }

    const getSummaryInfo = async () => {

        setLoadingSummaryInfo(true);

        try {
            const formData = new FormData();
            formData.append('type', submissionType);
            formData.append('mitraId', mitraReducer?.mitraInfo?.id)
            formData.append('pitchClient', pitchClient);
            formData.append('files', file);
            formData.append('isManagerMitra', mitraReducer?.mitraInfo?.managerMitraID ? false : true)

            const preIcsInfo = await getSamvaadiniSummaryInformation(formData);

            if (preIcsInfo?.data) {
                setSummaryInfo(preIcsInfo?.data);
            }

            if (preIcsInfo?.data?.isCapped) {
                notification['warning']({
                    message: `Candidates are capped at ${preIcsInfo?.data?.cappedValue || '2000'} per session.`
                })
            }
            else if(preIcsInfo?.data?.dailyLimitReached) { // max per day
                notification['warning']({
                    message: preIcsInfo?.data?.dailyLimitReachedMessage
                })
            }   

            setLoadingSummaryInfo(false);
        } catch (err) {
            setLoadingSummaryInfo(false);
            console.log(err);
        }

    }

    const resetSteps = () => {
        if (isNonReferral) {
            setEligibleSteps([
                {
                    id: 0,
                    label: 'Upload File',
                    subLabel: 'Upload',
                    mobileLabel: 'Upload File',
                    key: 'upload',
                    isActive: true, // to determine the active layout
                    isCompleted: false, // to determine if step is already completer
                },
                {
                    id: 1,
                    label: 'Select Script',
                    subLabel: 'Script',
                    mobileLabel: 'Select Script',
                    key: 'script',
                    isActive: false,
                    isCompleted: false
                },
                {
                    id: 2,
                    label: 'Select Team Members',
                    subLabel: 'Telecallers',
                    mobileLabel: 'Select Members',
                    key: 'assignTC',
                    isActive: false,
                    isCompleted: false,
                },
                {
                    id: 3,
                    label: 'Preview',
                    subLabel: 'Preview',
                    mobileLabel: 'Preview',
                    key: 'preview',
                    isActive: false,
                    isCompleted: false
                }
            ])
        }
        else {
            setEligibleSteps([
                {
                    id: 0,
                    label: 'Upload File',
                    subLabel: 'Upload',
                    mobileLabel: 'Upload File',
                    key: 'upload',
                    isActive: true, // to determine the active layout
                    isCompleted: false, // to determine if step is already completer
                },
                {
                    id: 1,
                    label: 'Select Script',
                    subLabel: 'Script',
                    mobileLabel: 'Select Script',
                    key: 'script',
                    isActive: false,
                    isCompleted: false
                },
                {
                    id: 3,
                    label: 'Preview',
                    subLabel: 'Preview',
                    mobileLabel: 'Preview',
                    key: 'preview',
                    isActive: false,
                    isCompleted: false
                }
            ])
        }
    }

    return (
        <div className='gray-main-container'>
            <div style={{ marginBottom: '32px' }} className="back-navigation d-flex align-items-center" onClick={() => history.push('/samvadini-v2')}>
                <LeftArrow />
                <span className='back-text'>Back</span>
            </div>

            {/* Advertisement markup */}
            <SamvaadiniAdvertisement isExpanded={false} header='File Upload' icon={Thumbsup} />

            {/* Markup to show steps bar */}
            <div className="file-upload-steps-container">

                {
                    eligibleSteps.map((step, index) => (
                        <StepsIndicatorDesktop step={step} handleStepClick={handleStepClick} key={step?.id} />
                    ))
                }
            </div>

            <div className="file-upload-steps-container-mobile" style={{ margin: "24px" }}>

                {
                    eligibleSteps.map((step, index) => (
                        <StepsIndicatorMobile index={index} step={step} key={index} steps={eligibleSteps} handleStepClick={handleStepClick} />
                    ))
                }
            </div>


            <div className="session-creation-header inter-medium-14-20" style={{ margin: '48px 32px 0px', textTransform: 'capitalize' }}>{currentStepSubLabel}</div>
            {/* <Divider style={{margin: '24px 32px 16px', width: '90%', minWidth: '0%'}}/> */}

            {
                currentStep === 'upload' ?
                    <FileUpload
                        goToNextScreen={goToNextScreen}
                        file={file}
                        setFile={setFile}
                        resetSteps={resetSteps}
                        isNonReferral={(val) => showNonReferralFlow(val)}
                    />
                    : null
            }

            {
                currentStep === 'script' ? <SelectScript goToNextScreen={() => { isNonReferral ? goToNextScreen("assignTC") : goToNextScreen("preview") }} setPitchClient={setPitchClient} /> : null
            }

            {
                currentStep === 'assignTC' ? <SelectTelecallers allTCList={mitraTeamList} goToNextScreen={goToNextScreen} returnSelectedTCList={(tcList) => onTCListSelected(tcList)} selectedTCFromParent={selectedTCList} /> : null
            }

            {
                currentStep === 'preview' ? <SummaryScreen
                    submitForm={submitForm}
                    summaryInfo={summaryInfo}
                    getSummaryInfo={getSummaryInfo}
                    pitchClient={pitchClient}
                    loadingSummaryInfo={loadingSummaryInfo}
                    submissionType={submissionType}
                    loader={loader}
                    isNonReferral={isNonReferral}
                    selectedTCList={selectedTCList}
                /> : null
            }
        </div>
    )
}

export default SamvaadiniFileUploadContainer