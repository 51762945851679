import axios from 'axios';
import axiosInstance from './interceptorService';

export const getpopularCities = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/referral/getPremiumJobsCities`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getClientNames = async (cityPreference) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/referral/getClientNames?city=${cityPreference}`;
    return axiosInstance.get(url, { withCredentials: true });
}

export const getJobLocations = async(cityPreference, clientPreference) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/referral/getAvailableJobLocationsByCityAndCompany?city=${cityPreference}&company=${clientPreference}`;
    return axiosInstance.get(url, { withCredentials: true });   
}

export const basicFormSubmit = async (payloadObj) => {
  const data = new FormData();
  data.append('userName', payloadObj?.candidateName);
  data.append('userPhoneNumber', payloadObj?.phoneNumber);
  data.append('sourceId', payloadObj?.sourceId);
  data.append('source', 'mitra-leader');
  data.append('jobId', null);
  data.append('jobDemandId', null);
  data.append('clientName', payloadObj?.clientName);
  data.append('mitraPhoneNumber', payloadObj?.mitraPhoneNumber);
  data.append('team', payloadObj?.team);
  data.append('city', payloadObj?.city);
  data.append('locality', payloadObj?.locality);

  let url = `${process.env.REACT_APP_BACKEND_URL}/referral/basicFormSubmit`;
  return axiosInstance.post(url, data, { withCredentials: true });
};