import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, message, notification } from 'antd';
import { CopyOutlined, UserAddOutlined, WhatsAppOutlined, EnvironmentOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import './LeadsFromPostersListingStyle.scss'

const LeadViewCard = ({ leadData = {
    "name": "",
    "phoneNumber": "",
    "createdAt": "",
    "city": "",
    "companyName": "",
    "logo": "", //logo key will not be sent for viewing leads for a particular poster
    "candidateId": ""
}, leadInd, showCompanyLogo = false }) => {
    const history = useHistory()
    const [showJobRequirementsListing, setShowJobRequirementsListing] = useState(true);

    const onCopyPhoneClicked = () => {
        navigator.clipboard.writeText(leadData.phoneNumber)
            .then(() => {
                message.success("Phone number copied!");
                notification.open({
                    message: `${leadData.phoneNumber} copied to clipboard !`,
                    // key,
                    duration: 5,
                    placement : 'topRight',
                    icon: <CheckCircleOutlined className='green-positive' />,
                });
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
                message.success('Failed to copy link');
            });
    }

    const onWhatsappClicked = () => {
        const whatsappURL = `https://wa.me/${leadData.phoneNumber}`;
        window.open(whatsappURL, '_blank');
    }

    const onReferCandidateClicked = () => {
        let client = leadData.companyName
        if (client) {
            client = client.replace(/_3PL/g, ' With Mitra').replace(/_Salary/g, ' - Salary')
        }
        const prams = `navigateTo=addSingleCandidate${client ? `&client=${client}` : ''}`;
        history.push('/add-single-candidate?' + prams);
    }

    return (
        <div className="lead-view-card-parent justify-content-between align-items-center w-100">
            <div className='lvc-info d-flex align-items-center'>
                {
                    showCompanyLogo ?
                        <div className='lvc-company-logo'>
                            <img src={leadData.logo} alt="comp_logo" className='lvc-company-logo-img' />
                        </div>
                        :
                        null
                }


                <div className='lvc-candidate-info'>
                    <div className='lvc-candidate-name'>
                        {leadData.name}
                    </div>

                    <div className='lvc-location-and-registered-on d-flex align-items-center'>
                        <div className='lvc-location lvc-sub-txt d-flex align-items-center'>
                            <EnvironmentOutlined className='lvc-location-img' />
                            <span className='lvc-location-txt'>
                                {leadData.city}
                            </span>
                        </div>
                        <div className='lvc-registered-on-txt lvc-sub-txt'>
                            &nbsp;• registered on {moment(leadData.createdAt).format("Do MMM YYYY")}
                        </div>
                    </div>
                </div>
            </div>

            <div className='lvc-btns d-flex'>
                <div className='lvc-copy-message-mobile d-flex'>
                    <Button className='lvc-copy-phone-btn lvc-btn d-flex align-items-center jp-cp justify-content-center' onClick={() => onCopyPhoneClicked()}>
                        <CopyOutlined className='lvc-copy-phone-btn-img lvc-btn-img-orange' />
                        <div className='lvc-copy-phone-btn-txt'>
                            {leadData.phoneNumber}
                        </div>
                    </Button>

                    <Button className='lvc-whatsapp-btn lvc-btn lvc-btn-margin d-flex align-items-center jp-cp justify-content-center' onClick={() => onWhatsappClicked()}>
                        <WhatsAppOutlined className='lvc-whatsapp-btn-img lvc-btn-img-orange' />
                        <div className='lvc-whatsapp-btn-txt show-only-desktop'>
                            Message
                        </div>
                    </Button>
                </div>

                <div className='lvc-refer-btn-container'>
                    <Button className='lvc-refer-btn lvc-btn lvc-btn-margin d-flex align-items-center jp-cp justify-content-center' onClick={() => onReferCandidateClicked()}>
                        <UserAddOutlined className='lvc-refer-btn-img lvc-btn-img-white' />
                        <div className='lvc-refer-btn-txt d-flex'>
                            Refer <span className='show-only-desktop'> &nbsp;Candidate </span>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default LeadViewCard; 