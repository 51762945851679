import { Modal, Avatar, Timeline, Button, Empty, notification, Popover, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import {WhatsAppOutlined, CheckCircleOutlined} from '@ant-design/icons';
import CopyTextCheckMark from '../../static/svg/CopyTextCheckMark';
import CrossIcon from '../../static/svg/CrossIcon';
import CallIcon from '../../static/svg/CallIcon';
import CopyIcon from '../../static/svg/CopyIcon';
import ClockIcon from '../../static/svg/ClockIcon';
import PlusCircle from '../../static/svg/PlusCircle';
import CustomCallNotes from '../customCallNotes';
import LeftArrow from '../../static/svg/LeftArrow';
import AddCallNote from '../CallNoteV2/AddCallNote';
import UserOctagon from '../../static/svg/UserOctagon';
import EditPencilIcon from '../../static/svg/EditPencilIcon';
import CustomSearchDropdown from '../SamvaadiniV2/components/CustomSearchDropdown';
import WhatsappTemplateModal from '../whatsappTemplateModal/WhatsappTemplateModal';
import PopoverDropdown from '../SamvaadiniV2/components/PopoverDropdown';
import UpArrow from '../../static/svg/UpArrow';
import DownArrow from '../../static/svg/DownArrow';
import LocationPin from '../../static/svg/LocationPin';
import { keyToIconMap } from '../../constants/utils';
import CloudUploadIcon from '../../static/svg/CloudUploadIcon';
import { getOverallTeamCount } from '../../service/reportService';
import { fetchApplicationDetails, reAssignLeadsV2 } from '../../service/LeadsPageV2Service';
import Lottie from 'react-lottie';
import LoadingAnimation from './../../static/lottie/blue-circle-loader.json';
import BlueTickAnimation from './../../static/lottie/blue-circle-tick.json';
import { getCallHistory } from '../../service/kaleyraCallService';
import moment from 'moment';
import CONSTANTS from '../../constants/constants';
import PauseIcon from '../../static/svg/PauseIcon';
import PlayIcon from '../../static/svg/PlayIcon';
import Audio from './components/Audio';
import { useHistory } from 'react-router-dom';
import InfoIcon from '../../static/svg/InfoIcon';
import SearchIcon from '../../static/svg/SearchIcon';

const { Option } = Select;

const MODAL_CONTENTS = {
    "ADD_NOTES": "ADD_NOTES",
    "DEFAULT": "DEFAULT",
    "LEAD_REASSIGNMENT": "LEAD_REASSIGNMENT",
    'LOTTIE_VIEW': 'LOTTIE_VIEW'
};

const DEFAULT_TAB_TYPES = {
    "CALL_NOTES": "CALL_NOTES",
    "APPLICATION_DETAILS": "APPLICATION_DETAILS"
}

function LeadDetailsPopup({
    openDetailsModal, 
    setOpenDetailsModal, 
    setShowChatbox,
    leadInfo, 
    mitraReducer, 
    getLeadsDashboardConfiguration,
    setSelectedRhId,
    setSupportConversationId,
    supportConversationId,
    source='default',
    filters}) {

    const [showWhatsappMessageModal, setShowWhatsappMessageModal] = useState(false);
    const [leadApplicationDetails, setLeadApplicationDetails] = useState([]);
    const [allApplications, setAllApplications] = useState([]);
    const [showCompanyFilter, setShowCompanyFilter] = useState(false);
    const [currentApplication, setCurrentApplication] = useState({});
    const [teamDropdownOptions, setTeamDropdownOptions] = useState([]);
    const [selectedMembersToReassignLeadsTo, setSelectedMembersToReassignLeadsTo] = useState([]);
    const [stackImages, setStackImages] = useState({frontImage:'', backImage: ''});
    const [animation, setAnimation] = useState(LoadingAnimation);
    const [callHistoryData, setCallHistoryData] = useState([]);
    const [hasReassignAccess, setHasReassignAccess] = useState(false);
    const history = useHistory();

    const [selectedCompany, setSelectedCompany] = useState('');

    const [lead, setLead ]= useState({});

    const [timeline, setTimeline] = useState([]);
    const [referralDetails, setReferralDetails] = useState({});

    const onExpand = (timelineItemIndex) => {
        const modifiedTimeline = [...timeline];
        modifiedTimeline[timelineItemIndex].isExpanded = !modifiedTimeline[timelineItemIndex].isExpanded;
        setTimeline([...modifiedTimeline]);
    }

    const [modalContent, setModalContent] = useState(MODAL_CONTENTS.DEFAULT);
    const [activeCandidateDetailsTab, setActiveCandidateDetailsTab] = useState(DEFAULT_TAB_TYPES.APPLICATION_DETAILS);

    const [copyOperationSuccessful, setCopyOperationSuccessful] = useState(false);

    const onCompanyFilterSelection = (id) => {
        const modifiedCompanyFilterItems = [...allApplications];

        const item = allApplications.find((option) => option.id === id);

        setSelectedRhId(item?.recommendationHistoryId);
    
        for (let i = 0; i < modifiedCompanyFilterItems.length; i++) {
          if(modifiedCompanyFilterItems[i].id === item.id) {
            modifiedCompanyFilterItems[i].isActive = true;
            setSelectedCompany(modifiedCompanyFilterItems[i].companyName);
            setCurrentApplication(modifiedCompanyFilterItems[i]);
            
            // Call the API to get changed application details.
            getApplicationDetails(modifiedCompanyFilterItems[i].applicationId, 'applicationId');
          } else {
            modifiedCompanyFilterItems[i].isActive = false;
          }
        }
    }

    const copyPhoneNumber = async() => {
        try {
            await navigator.clipboard.writeText(referralDetails?.phoneNumber);
            setCopyOperationSuccessful(true);
            setTimeout(() => setCopyOperationSuccessful(false), 1000);
            notification.open({
              message: `${referralDetails?.phoneNumber} copied to clipboard !`,
              // key,
              duration: 5,
              placement: 'topRight',
              icon: <CheckCircleOutlined className="green-positive" />,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const onTelecallerSearch = (val) => {
        // if(!val) {
        //     // set dropdown values to empty arr
        //     setTeamDropdownOptions([]);
        //     return
        // }
  
        let data = {
            search: val
        }
  
        getOverallTeamCount(data).then((teamListRes) => {
          if(teamListRes?.data?.teamList?.length) {
              const teamList = [];
  
                for(let i=0;i<teamListRes?.data?.teamList?.length; i++) {
                    if(teamListRes?.data?.teamList[i].id === referralDetails?.referrerDetails?.referrerId) continue;
                    teamList.push({label:teamListRes?.data?.teamList[i].name, value: teamListRes?.data?.teamList[i].name, data: teamListRes?.data?.teamList[i]});
                }
  
                //set dropdown values in the filter obj
                setTeamDropdownOptions([...teamList]);
                
          } else {
              // set dropdown values to empty arr
              setTeamDropdownOptions([]);
              
          }
        }).catch(err=> {
            // set dropdown values to empty arr
            setTeamDropdownOptions([]);
        })
    }
    
    const onSelectTelecaller = (memberId) => {

        let val = teamDropdownOptions.find((option) => option?.data?.id === memberId);

        val = [val];

        let singleTelecaller = [];
        if(val.length > 1) {
            singleTelecaller = [val[1]];
        } else {
            singleTelecaller = [...val]
        }
        singleTelecaller.map((items)=>{
            items.isSelected = true
        });
    
        setSelectedMembersToReassignLeadsTo([...singleTelecaller]);
    };

    const getApplicationDetails = async(applicationId, property) => {
        const payload = {
            mitraId: mitraReducer?.mitraInfo?.id,
            [property]:applicationId
        };

        try {
            const applicationDetailsResponse = await fetchApplicationDetails(payload);

            if(applicationDetailsResponse?.data?.data?.timeline) {
                setTimeline([...applicationDetailsResponse?.data?.data?.timeline]);
            } else {
                setTimeline([]);
            }

            if(applicationDetailsResponse?.data?.data?.referralDetails) {
                setReferralDetails(applicationDetailsResponse?.data?.data?.referralDetails);
                setSupportConversationId(applicationDetailsResponse?.data?.data?.referralDetails?.conversationId)
            }
            
        } catch(e) {
            console.log(e)
        }
    }

    useEffect(() => {
      if(leadInfo?.applications?.length) {
        const allApplications = [...leadInfo?.applications];

        for (let application of allApplications) {
            application.name = application.companyName;
            application.isActive = false;
            application.id = application.applicationId;
        }

        allApplications[0].isActive = true;
        setAllApplications(allApplications);
        setSelectedCompany(allApplications[0].companyName);
        setCurrentApplication(allApplications[0]);

        setSelectedRhId(allApplications[0].recommendationHistoryId);
        

        if(allApplications[0].applicationId) {
            getApplicationDetails(allApplications[0].applicationId, 'applicationId');
        } else if(leadInfo.leadPoolId) {
            getApplicationDetails(leadInfo.leadPoolId, 'leadPoolId');
        }
      }
    }, [leadInfo]);

    useEffect(() => {
    
        if(allApplications.length > 1) {
            if(allApplications[0].applicationId !== currentApplication.applicationId) {
                setStackImages({
                    frontImage: currentApplication?.companyLogo,
                    backImage: allApplications[0]?.companyLogo
                });
              } else {
                setStackImages({
                    backImage: allApplications[1]?.companyLogo,
                    frontImage: allApplications[0]?.companyLogo
                });
              }
        }
    }, [currentApplication]);

    useEffect(() => {
        setLead({
            recommendationHistoriesId: [currentApplication?.recommendationHistoryId],
            mitraName: currentApplication?.referredMitraName,
            id: leadInfo?.userId,
            companies: [currentApplication?.name],
            phoneNumber: leadInfo?.candidatePhoneNumber
        })

        setLeadApplicationDetails([{companyName: currentApplication.name, firstName: leadInfo?.candidateFirstName}]);
    }, [leadInfo, currentApplication])
    

    useEffect(() => {
        if (mitraReducer && mitraReducer.allowedFeatures) {
          setHasReassignAccess(mitraReducer?.allowedFeatures?.[CONSTANTS.CAN_REASSIGN_LEADS]?.isEnabledOverall)
        }
      }, [mitraReducer.allowedFeatures])

    const getCallNotesInformation = async() => {
        const payload = {
            id: leadInfo?.userId,
            recommendationHistoryId: referralDetails?.recommendationHistoryId,
            mitraId: mitraReducer?.mitraInfo?.id
        };

        try {
            const callHistoryInformation = await getCallHistory(payload);

            if(callHistoryInformation?.data?.length) {
                setCallHistoryData([...callHistoryInformation.data]);
            } else {
                setCallHistoryData([]);
            }
        } catch (err){
            setCallHistoryData([]);
            console.log(err)
        }
    }

    const onButtonClick = () => {
        const payload = {
            name: referralDetails?.name,
            phoneNumber: referralDetails?.phoneNumber,
            userId: referralDetails?.userId,
            scheduledInterviewId: referralDetails?.scheduledInterviewId,
            companyName: currentApplication?.name,
            jobId: '',
            jobDemandId: '',
            companyCity: currentApplication?.referredCity,
            meta: '',
            phoneNumber: referralDetails?.phoneNumber,
            recommendationHistoryId: referralDetails?.recommendationHistoryId
        }
        console.log(payload, currentApplication, 'here');

        if(!payload.companyName) return;
        if (payload?.companyName?.toLowerCase().indexOf('zomato') !== -1) {
            history.push({
              pathname: '/zomato-additional-form',
              route: !payload.scheduledInterviewId ? 'jobRecommendationPage' : null,
              search: new URLSearchParams(payload).toString(),
            });
          } else if (payload?.companyName?.toLowerCase() === 'blinkit darkstore') {
            history.push({
              pathname: '/blinkit-darkstore-additional-form',
              route: !payload.scheduledInterviewId ? 'jobRecommendationPage' : null,
              search: new URLSearchParams(payload).toString(),
            });
          }
          else if (payload?.companyName?.toLowerCase() === 'blinkit') {
            history.push({
              pathname: '/blinkit-additional-form',
              route: !payload.scheduledInterviewId ? 'jobRecommendationPage' : null,
              search: new URLSearchParams(payload).toString(),
            });
          } else if (payload?.companyName?.toLowerCase().indexOf('swiggy soc') !== -1) {
            history.push({
              pathname: '/swiggy-soc-additional-form',
              route: !payload.scheduledInterviewId ? 'jobRecommendationPage' : null,
              search: new URLSearchParams(payload).toString(),
            });
          } else if (payload?.companyName?.toLowerCase() === 'zepto') {
            history.push({
              pathname: '/zepto-additional-form',
              route: !payload.scheduledInterviewId ? 'jobRecommendationPage' : null,
              search: new URLSearchParams(payload).toString(),
            });
          } else if (payload?.companyName?.toLowerCase().indexOf('swiggy') !== -1) {
            history.push({
              pathname: '/swiggy-additional-form',
              route: !payload.scheduledInterviewId ? 'jobRecommendationPage' : null,
              search: new URLSearchParams(payload).toString(),
            });
          } else {
            history.push({
              pathname: '/moreInfo',
              route: !payload.scheduledInterviewId ? 'jobRecommendationPage' : null,
              search: new URLSearchParams(payload).toString(),
            });
          }
    }

    const openChatTicket = () => {
        setShowChatbox(true);
        setOpenDetailsModal(false);
    }

    const defaultModalContents = ()=> (
        <>
            <div className="leads-filter-header">
                <span className='unbounded-semibold-20-24 plain-black'>Lead Details</span>
                <div style={{cursor: 'pointer'}} onClick={()=>setOpenDetailsModal(false) }>
                <CrossIcon height={18} width={18}/>
                </div>
            </div>

            <div className='leads-filter-content custom-scroll-bar' style={{overflowX: 'hidden'}}>
                
                <div className="ld-lead-info">
                    <div className='d-flex align-items-center justify-content-between flex-wrap flex-gap-12'>
                        <div className='d-flex align-items-center' style={{gap: '20px'}}>
                            <img src='/image/candidate-samvaadini.png' style={{height: '36px',width:'36px'}}/>
                            <div className='d-flex align-items-start flex-column'>
                            <span className='inter-semibold-16-24 plain-black'>{referralDetails?.name}</span>
                            <div className='d-flex align-items-center' style={{gap: '4px'}}>
                                <span className='inter-medium-12-18 color-252526'>{referralDetails?.phoneNumber}</span>
                                <div style={{cursor: 'pointer', marginTop: copyOperationSuccessful ? '-2px':''}} onClick={copyPhoneNumber}>
                                {
                                    copyOperationSuccessful ? <CopyTextCheckMark height={20} width={20} style={{marginTop:'-6px'}}/>:<CopyIcon height={20} width={20}/>
                                }
                                
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className='d-flex align-items-center' style={{gap:'24px'}}>
                            {
                                referralDetails?.tags?.map((tag, index) => (
                                    <div className='ic-tag' key={`tag-${index}`} style={{background: tag?.bgColor}}>
                                        {
                                            keyToIconMap(
                                                tag?.icon,
                                                {
                                                    strokeColor: '#FFF'
                                                }
                                            )
                                        }
                                        <span className='inter-semibold-12-18 plain-white'>{tag?.label || tag?.title}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className='d-flex align-items-center justify-content-between mt-24'>
                        <div className='d-flex align-items-center' style={{gap: '4px'}}>
                            <UserOctagon />
                            <span className='inter-regular-14-20 color-838383 visibility-desktop'>Assigned to: <span className='inter-semibold-14-16' style={{marginLeft: '4px', lineHeight: '20px'}}>{referralDetails?.referrerDetails?.name}</span></span>
                            <span className='inter-semibold-14-16 visibility-mobile' style={{marginLeft: '4px', lineHeight: '20px'}}>{referralDetails?.referrerDetails?.name}</span>
                            
                            {
                                hasReassignAccess && source === 'default' ? (
                                    <div onClick={()=>
                                    {
                                        onTelecallerSearch('');
                                        setModalContent(MODAL_CONTENTS.LEAD_REASSIGNMENT);
                                    }}>
                                        <EditPencilIcon />
                                    </div>
                                ): null
                            }
                            
                        </div>

                        {
                            source === 'default' ? (
                                <div className='d-flex align-items-center flex-gap-16'>
                                    <a
                                    href={`tel:+91-${referralDetails?.phoneNumber}`}>
                                        <CallIcon height={22} width={22} strokeColor='#1D5FFF'/>
                                    </a>
                                    {
                                        leadInfo.leadPoolId ? null: (
                                            <div onClick={()=> setShowWhatsappMessageModal(true)} className='cursor-pointer' style={{marginTop: '-3px'}}>
                                                <WhatsAppOutlined style={{color: '#1D5FFF',fontSize: '20px'}}/>
                                            </div>
                                        )
                                    }
                                    
                                </div>
                            ): null
                        }
                        
                    </div>
                </div>

                <div style={{borderBottom: '1px solid #e2e2e2'}}>
                    <div className="filtered-candidate-tab-container d-flex">
                        <div 
                        className={`filtered-candidate-single-tab ${activeCandidateDetailsTab === DEFAULT_TAB_TYPES.APPLICATION_DETAILS ? 'filtered-candidate-active-tab': null}`}
                        onClick={()=>setActiveCandidateDetailsTab(DEFAULT_TAB_TYPES.APPLICATION_DETAILS)}
                        >
                            Timeline
                        </div>

                        {
                            source === 'default' ? (
                                <div 
                                    className={`filtered-candidate-single-tab ${activeCandidateDetailsTab === DEFAULT_TAB_TYPES.CALL_NOTES ? 'filtered-candidate-active-tab': null}`}
                                    onClick={()=>{
                                        setActiveCandidateDetailsTab(DEFAULT_TAB_TYPES.CALL_NOTES);
                                        getCallNotesInformation();
                                    }
                                }>
                                    Call Notes
                                </div>
                            ): null
                        }
                        
                    </div>
                </div>

                {
                    // Section to display candidate's application details
                    activeCandidateDetailsTab === DEFAULT_TAB_TYPES.APPLICATION_DETAILS ? (
                        <div className="new-ld-timeline-container">
                            {/* <img src='https://uploads-a.vahan.co/fileUploder/2024-09-13/N2tESo-frame-381.png' style={{height: '40px', width:'40px', marginBottom: '32px',display: 'flex'}} /> */}

                            <div style={{marginBottom: '32px'}} className='d-flex align-items-center flex-gap-16'>
                                {
                                    leadInfo?.leadPoolId ?  (
                                        <img src={leadInfo?.applications?.[0]?.companyLogo} style={{height: '48px', width:'48px',borderRadius: '4px'}} />
                                    ):
                                    leadInfo?.applications?.length > 1 ? (
                                        <div className="image-stack">
                                            <div className="image-container">
                                                {/* Gray indicator behind */}
                                                <div className="indicator"></div>

                                                {/* First image in front */}
                                                <img src={stackImages?.frontImage} alt="Front Image" className="image-front" />

                                                {/* Second image behind the first */}
                                                <img src={stackImages.backImage} alt="Back Image" className="image-back" />
                                            </div>
                                        </div>
                                    ):(
                                        <img src={leadInfo?.applications?.[0]?.companyLogo} style={{height: '48px', width:'48px',borderRadius: '4px',border: '1px solid #80808063'}} />
                                    )
                                }

                                {
                                    allApplications.length > 1 ? (
                                        // <PopoverDropdown
                                        // content={allApplications}
                                        // visible={showCompanyFilter}
                                        // setVisible={setShowCompanyFilter}
                                        // onSelect={(item) => onCompanyFilterSelection(item)}
                                        // >
                                        //     <div className="custom-dropdown-box" style={{width: '116px'}}>
                                        //         <span className="inter-medium-14-20 color-323232" style={{ flex: 4, textTransform:'capitalize' }}>
                                        //             {selectedCompany}
                                        //         </span>
                                        //         <div style={{ flex: 1 }}>{showCompanyFilter ? <UpArrow /> : <DownArrow />}</div>
                                        //     </div>
                                        // </PopoverDropdown>

                                        <Select
                                        className="mobile-sortby-dd-filter"
                                        style={{border: 0, width: '140px'}}
                                        value={selectedCompany}
                                        onChange={onCompanyFilterSelection}
                                        >
                                            {
                                                allApplications.map((item, index) => (
                                                <Option key={index} value={item?.id}>{item?.companyName}</Option>
                                                ))
                                            }
                                        </Select>
                                    ): null
                                }
                                
                                {
                                    currentApplication?.referredCity ? (
                                        <div className='ic-location-details'><LocationPin /> <span className='inter-regular-12-16 color-252526'>{currentApplication?.referredCity}</span></div>
                                    ): null
                                }
                                
                            </div>

                            <div style={{maxWidth: window.innerWidth < 800 ? '100%':'80%'}}>
                                
                                <Timeline>
                                    {
                                        timeline?.map((timelineItem, index) =>(
                                            <Timeline.Item
                                            key={index}
                                            dot={
                                                <div style={{height:'8px',width: '8px',borderRadius:'50%', backgroundColor: timelineItem.isCompleted ? '#2C8A34': '#B3B3B3'}}></div>
                                            }
                                            style={{paddingBottom: '32px'}}
                                            >
                                                <div className='d-flex align-items-start flex-wrap' style={{gap:'8px'}}>
                                                    {/* Main timeline icon */}
                                                    <div style={{marginTop: '4px'}}>
                                                        {
                                                            timelineItem?.icon ? keyToIconMap(
                                                                timelineItem?.icon,
                                                                {
                                                                    strokeColor: timelineItem.isCompleted ? '#2C8A34': '#252526',
                                                                    fillColor: null,
                                                                    height: 24,
                                                                    width: 24,
                                                                }
                                                            ): ''
                                                        }
                                                    </div>

                                                    {/* Timeline header and subheader */}
                                                    <div className='d-flex align-items-start flex-column'>
                                                        <span className='inter-regular-16-24' style={{color: timelineItem.isCompleted ? '#2C8A34': '#252526'}}>{timelineItem?.header?.text}</span>
                                                        <div className='d-flex align-items-center flex-gap-4'>
                                                            {
                                                                timelineItem?.subHeader?.icon ? keyToIconMap(
                                                                    timelineItem?.icon,
                                                                    {
                                                                        strokeColor: timelineItem?.subHeader?.textColor,
                                                                        fillColor: timelineItem?.subHeader?.bgColor,
                                                                        height: 16,
                                                                        width: 16,
                                                                    }
                                                                ): ''
                                                            }
                                                            <span className='inter-semibold-12-18' style={{color: timelineItem.isCompleted ? '#2C8A34': '#252526'}}>{timelineItem?.subHeader?.text}</span>
                                                        </div>
                                                    </div>

                                                    {/* Timeline tags/chips */}
                                                    {
                                                        timelineItem.tags?.map((stepTag, stepTagIndex) => (
                                                            <div key={`stepTag+ ${stepTagIndex}`} className='ic-tag' style={{backgroundColor: stepTag?.bgColor || '#FFF'}}>
                                                                { stepTag?.icon ? keyToIconMap(
                                                                    stepTag?.icon, 
                                                                    {
                                                                        strokeColor: stepTag.textColor,
                                                                        fillColor: stepTag.bgColor,
                                                                        height: 20,
                                                                        width: 20,
                                                                    }
                                                                ): '' }
                                                                <span className='inter-semibold-12-18' style={{color: stepTag?.textColor || '#FFF'}}>{stepTag.title}</span>
                                                            </div>
                                                        ))
                                                    }

                                                    {/* Timeline button */}
                                                    {
                                                        timelineItem?.button?.text ? (
                                                            <Button className='primary-btn-styles-v2 d-flex align-items-center flex-gap-8' onClick={()=>onButtonClick()}>
                                                                <CloudUploadIcon strokeColor='#FFF'/>
                                                                <span className='plain-white inter-semibold-14-16'>{timelineItem?.button?.text}</span>
                                                            </Button>
                                                        ): null
                                                    }

                                                    {/* Timeline expandable section indicator */}
                                                    {
                                                        timelineItem?.isExpandable ? (
                                                            <div 
                                                            style={{marginLeft: '8px'}}
                                                            className='d-flex align-items-center flex-gap-8 cursor-pointer user-select-none' 
                                                            onClick={()=>onExpand(index)}>
                                                                <span className='inter-semibold-16-24 color-1D5FFF'>{timelineItem?.isExpanded ? 'Hide Steps': 'Show Steps'}</span>
                                                                {timelineItem?.isExpanded ? <UpArrow strokeColor='#1D5FFF' height={16} width={16}/>: <DownArrow strokeColor='#1D5FFF' height={16} width={16}/> }
                                                            </div>
                                                        ): null
                                                    }
                                                </div>

                                                {/* Timeline expandable section contents */}
                                                {
                                                    timelineItem?.isExpandable && timelineItem?.isExpanded ? (
                                                        <div className='child-timeline-items-container' style={{backgroundColor: '#F6F6F6', borderRadius: '8px', padding: '20px 24px 0px 24px', marginTop: '8px', marginLeft: '16px'}}>
                                                            <Timeline>
                                                                {
                                                                    timelineItem?.childItems?.map((childItem, childItemIndex) => (
                                                                        <Timeline.Item
                                                                        key={`childItem + ${childItemIndex}`}
                                                                        dot={<span className='inter-regular-14-20 plain-black'>{`Step ${childItemIndex + 1}`}</span>}
                                                                        style={{paddingBottom: '32px'}}
                                                                        >
                                                                            <div className='d-flex align-items-start flex-column' style={{paddingLeft: '22px'}}>
                                                                                <span className='inter-regular-16-24' style={{color: '#252526'}}>{childItem.header}</span>
                                                                                <span className='inter-semibold-12-18' style={{color: childItem.textColor || '#252526'}}>{childItem.subHeader}</span>
                                                                            </div>
                                                                        </Timeline.Item>
                                                                    ))
                                                                }
                                                            </Timeline>
                                                        </div>
                                                    ): null
                                                }
                                                <div>


                                                </div>
                                            </Timeline.Item>
                                        ))
                                    }
                                    
                                </Timeline>

                            </div>

                            <div className="floating-rider-image visibility-desktop">
                                <img src='/image/rider.webp' className='floating-rider-image-styles'/>
                            </div>
                        </div>
                    ): null 
                }

                {
                    activeCandidateDetailsTab === DEFAULT_TAB_TYPES.CALL_NOTES ? (
                        <div className="new-ld-notes-container">
                            <div className='ld-notes-top-section d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-start' style={{gap: '8px'}}>
                                    <CallIcon height={32} width={32} strokeColor='#838383'/>
                                    <div className='d-flex flex-column align-items-start'>
                                        <span className='unbounded-semibold-24-28 color-252526'>{callHistoryData?.length || '0'}</span>
                                        <span className='inter-regular-16-24 color-838383'>Call Attempts</span>
                                    </div>
                                </div>

                                <div 
                                onClick={()=>setModalContent(MODAL_CONTENTS.ADD_NOTES)}
                                className='d-flex align-items-center cursor-pointer' 
                                style={{gap: '8px'}}>
                                    <PlusCircle />
                                    <span className='inter-semibold-14-16 color-1D5FFF'>Add note</span>
                                </div>
                            </div>

                            <div className="call-note-card-main-container">

                                {
                                    callHistoryData?.length ? 
                                    callHistoryData.map((callHistory, index) => (
                                        <div key={callHistory?.id} className="individual-call-note-card new-theme-shadow-light">
                                            <div className='d-flex align-items-center justify-content-between w-100 flex-wrap flex-gap-12'>
                                                <span className='inter-semibold-16-24 color-252526'>{moment(callHistory?.createdAt).format('DD/MM/YYYY')}</span>
                                                <div className='d-flex align-items-center flex-gap-8'>
                                                    <span className='inter-bold-16-18' style={{color: callHistory?.isCallConnected ? '#4EBB57': '#FF4545'}}>{callHistory?.isCallConnected ? 'Connected': 'Not Connected'}</span>
                                                    {
                                                        callHistory?.tag ? (
                                                            <div className='cn-call-status' style={{background: callHistory?.isCallConnected ? '#EFF4D4': '#FEDCDC'}}>{callHistory.tag}</div>
                                                        ): null
                                                    }
                                                    
                                                </div>
                                            </div>

                                            <div className='d-flex align-items-center call-details-data'>
                                                
                                                <div className='d-flex align-items-start flex-column'>
                                                    <span className='inter-regular-12-16 color-AFAFAF'>Time</span>
                                                    <span className='inter-semibold-16-24 color-252526'>{
                                                        callHistory?.createdAt ? 
                                                        moment(callHistory?.createdAt).format('hh:mm a'): '-'
                                                    }</span>
                                                </div>

                                                <div className='d-flex align-items-start flex-column'>
                                                    <span className='inter-regular-12-16 color-AFAFAF'>Call Duration</span>
                                                    <span className='inter-semibold-16-24 color-252526'>{
                                                        callHistory?.duration ?
                                                        parseInt(callHistory?.duration) > 60 ? (parseInt(callHistory.duration)/60).toFixed(2) + ' mins': parseInt(callHistory.duration) + ' sec': '-'
                                                    }</span>
                                                </div>

                                                <div className='d-flex align-items-start flex-column'>
                                                    <span className='inter-regular-12-16 color-AFAFAF'>Client</span>
                                                    <span className='inter-semibold-16-24 color-252526'>{callHistory?.companyName || '-' }</span>
                                                </div>
                            

                                                {/* <div className='d-flex align-items-start flex-column'>
                                                    <span className='inter-regular-12-16 color-AFAFAF'>Follow up</span>
                                                    <span className='inter-semibold-16-24 color-252526'>Tomorow</span>
                                                </div> */}
                                            </div>

                                            {
                                                callHistory?.comment ? (
                                                    <div className='cn-remarks-container'>
                                                        <span className='cn-remarks-header'>Remarks</span>
                                                        <div className='cn-remark'>
                                                            “{callHistory?.comment}”
                                                        </div>
                                                    </div>
                                                ): null
                                            }

                                            {
                                                callHistory?.recordingUrl ? (
                                                    <Audio url={callHistory?.recordingUrl}/>
                                                ):null
                                            }
                                            
                                        </div>
                                    )): (
                                    <div className='d-flex align-items-center justify-content-center w-100 flex-column'>
                                        <Empty description=''/>
                                        <span className='inter-medium-14-20'>No call notes found</span>
                                    </div>
                                  )
                                }

                            </div>
                        </div>
                    ): null
                }
                

            </div>

            {
                source === 'default' ? ( // default means its from leads page
                    !leadInfo?.leadPoolId ? ( // if its a uploaded and not referred lead then dont allow to create tickets
                    <div className="leads-filter-footer">
                        <Button className='secondary-btn-styles-v2 leads-filter-footer-button' onClick={()=>openChatTicket()}> 
                            <img src='/image/Chat_Circle_Dots.png' style={{height: '18px', width: '18px',objectFit: 'contain'}} />
                            <span className='btn-text-semibold-14-16 color-1D5FFF'>{supportConversationId ? 'Check Issue': 'Raise Issue'}</span>
                        </Button>
                    </div>
                    ): null
                ): null
            }
            
        </>
    );

    const formatFilters = () => {
        const filterPayload = {
            "filtersData": filters,
            "mitraIdForReassignmentArray": [],
            "reassignmentCount": 1,
            'typeOfLead': filters.typeOfLeads || 'referred'
        };

        if (filters.typeOfLeads === CONSTANTS.TYPE_OF_LEADS.PRE_REFERRAL) {
            filterPayload.leadPoolId = leadInfo?.leadPoolId;
        } else {
            filterPayload.recommendationHistoryId = referralDetails?.recommendationHistoryId
        }

        filterPayload.mitraIdForReassignmentArray = selectedMembersToReassignLeadsTo.map(member => member.data.id);

        if(!filterPayload.mitraIdForReassignmentArray || !filterPayload.mitraIdForReassignmentArray.length){
            notification['error']({
                message: 'Please select a telecaller for re-assignment.'
              });
            return null;
        }

        return filterPayload;
    }

    const reassignLead = async() => {
        const payload = formatFilters();

        if(!payload) return;

        setModalContent(MODAL_CONTENTS.LOTTIE_VIEW);

      try {
        const reassignmentTrigger = await reAssignLeadsV2(payload, mitraReducer?.mitraInfo?.id);

        if(reassignmentTrigger?.data?.success) {
          setAnimation(BlueTickAnimation);
        } else {
          // something 
        }

      } catch(err) {
        console.log(err)
      }
    }

    const leadReassignmentContent = () =>(
        <>
            <div className="leads-filter-header">
                <div className='d-flex align-items-center flex-gap-4'>
                    <div onClick={()=> setModalContent(MODAL_CONTENTS.DEFAULT)} className='cursor-pointer'>
                        <LeftArrow  strokeWidth={2}/>
                    </div>
                    <span className='unbounded-semibold-20-24 plain-black'>Re-assign Lead</span>
                </div>
                <div style={{cursor: 'pointer'}} onClick={()=>setOpenDetailsModal(false) }>
                <CrossIcon height={18} width={18}/>
                </div>
            </div>

            <div className='leads-filter-content custom-scroll-bar' style={{overflowX: 'hidden'}}>
                <div className='call-connection-type-tab-container new-theme-shadow-light mt-24'>
                    <span className='inter-regular-16-24 color-252526 d-flex'>Currently Assigned Telecallers</span>
                    <div className="telecaller-list">

                        {
                            referralDetails?.referrerDetails?.name ? <div className="telecaller">{referralDetails?.referrerDetails?.name}</div>: null
                        }
                    </div>
                </div>

                <div className='call-connection-type-tab-container new-theme-shadow-light mt-24 d-flex flex-gap-8 dsktp-alignment'>
                    <span className='inter-regular-16-24 color-252526 d-flex'>
                        Select New Telecallers
                        <span style={{top: '-5px',color: '#FF4545'}}>*</span>
                        <div className='cursor-pointer'>
                            <Popover 
                                content={'Lead will be assigned to selected telecaller'} 
                                title={null} 
                                // visible={true} 
                                trigger={'hover'}
                                className='ai-filtering-popup'
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placement='bottom'>
                                <span><InfoIcon height={14} width={14} strokeColor='#000'/></span>
                            </Popover>
                        </div>
                    </span>
                    {/* <CustomSearchDropdown 
                        placeholder={'Search for a telecaller'}
                        onSearch={(value)=>onTelecallerSearch(value)}
                        dropdownOptions={teamDropdownOptions}
                        onSelect={(data)=>onSelectTelecaller(data)}
                        value={selectedMembersToReassignLeadsTo}
                        checkboxGroupVisible={false}
                        inputBoxCustomClass='filter-search-box'
                        searchIconSize={16}
                        showSelection={true}
                    /> */}

                    <Select
                        placeholder={'Select a telecaller'}
                        className="mobile-sortby-dd-filter no-adjacent-borders"
                        style={{border: 0}}
                        value={selectedMembersToReassignLeadsTo?.[0]?.label || null}
                        onChange={(value)=>onSelectTelecaller(value)}
                        suffixIcon={<SearchIcon />}
                        dropdownClassName='custom-scroll-bar'
                        showSearch
                        filterOption={(input, option) =>
                            (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {
                        teamDropdownOptions.map((item, index) => (
                            <Option key={index} value={item?.data?.id} className='text-capitalise'>{item?.label}</Option>
                        ))
                        }
                    </Select>
                </div>
            </div>

            <div className="leads-filter-footer">
              <Button className='primary-btn-styles-v2 leads-filter-footer-button' onClick={()=>reassignLead()}> 
                <span className='btn-text-semibold-14-16 plain-white'>Confirm</span>
              </Button>
            </div>
        </>
    );

    const addCallNotesContent = () => (
        <>
            <div className="leads-filter-header">
                <div className='d-flex align-items-center flex-gap-4'>
                    <div onClick={()=> setModalContent(MODAL_CONTENTS.DEFAULT)} className='cursor-pointer'>
                        <LeftArrow  strokeWidth={2}/>
                    </div>
                    <span className='unbounded-semibold-20-24 plain-black'>Add Note</span>
                </div>
                <div style={{cursor: 'pointer'}} onClick={()=>setOpenDetailsModal(false) }>
                <CrossIcon height={18} width={18}/>
                </div>
            </div>

            <AddCallNote 
            applications={allApplications} 
            referralDetails={referralDetails} 
            leadDetails={leadInfo} 
            mitraReducer={mitraReducer}
            resetModal={()=>{
                getCallNotesInformation();
                setModalContent(MODAL_CONTENTS.DEFAULT)
            }}
            />
        </>
    );

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const lottieView = () => (
        <>
            <div className="leads-filter-header">
                <span className='unbounded-semibold-20-24 plain-black'>Re-assign Lead</span>
                <div style={{cursor: 'pointer'}} onClick={()=> {
                    getLeadsDashboardConfiguration();
                    setOpenDetailsModal(false)
                }}>
                    <CrossIcon height={18} width={18}/>
                </div>
            </div>
  
            <div className='new-call-note-main-container align-items-center justify-content-center d-flex flex-column mobile-full-height'>
                <Lottie 
                options={defaultOptions}
                height={120}
                width={120}
                speed={1}
                />
                <span className='inter-regular-16-24 color-252526'>
                    {
                        animation === LoadingAnimation ? 'Re-assigning Lead to New Telecaller': 'Lead have been re-assigned'
                    }
                </span>
            </div>
        </>
    );
    
  return (
    <>

        <Modal 
            title={null}
            visible={openDetailsModal}
            className="leads-filter-modal"
            closable={false}
            maskClosable={false}
            bodyStyle={{borderRadius: '8px',padding: '0px'}}
            footer={null}
            destroyOnClose={true}
        >

            {
                modalContent === MODAL_CONTENTS.DEFAULT ? defaultModalContents(): null
            }

            {
                modalContent === MODAL_CONTENTS.ADD_NOTES ? addCallNotesContent(): null
            }

            {
                modalContent === MODAL_CONTENTS.LEAD_REASSIGNMENT ? leadReassignmentContent(): null
            }

            {
                modalContent === MODAL_CONTENTS.LOTTIE_VIEW ? lottieView(): null
            }

        </Modal>
        
        {
            showWhatsappMessageModal ?
                <WhatsappTemplateModal
                lead={lead} // recommendationHistoryIds:[], mitraName:'', id, companies:[], parentPhoneNumber:[], phoneNumber:'', 
                leadDetails={leadApplicationDetails} // leadDetails:[{companyName: ''}]
                whatsAppMessageModal={showWhatsappMessageModal}
                whatsAppMessageModalCancel={()=>setShowWhatsappMessageModal(false)}
                ></WhatsappTemplateModal>
            : null
        }
    </>
  )
}

export default LeadDetailsPopup