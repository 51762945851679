import React, {useState,useEffect,useContext} from 'react';
import '../css/help.scss';
import SearchComponent from './common/globalSearch';
import { PageHeader,Anchor,Card,Typography,Empty,Pagination, message, Table, Tag, Button, Divider, Modal,notification, Input} from 'antd';
import {ArrowRightOutlined,SendOutlined} from '@ant-design/icons';
import { getMitraTeamMembersList, getMitraPhoneNumberFromId } from './../service/commonService'
import { searchIssues } from './../service/helpService'
import Ticket from './help/ticket.component';
import {AppContext} from '../App';
import moment from "moment";
import '../css/searchPage.scss';
import { getAllConversations, reOpenTicket } from '../service/ticketService';
import SideSheetComponent from './common/SideSheet';
import Chat from './help/chat.component';
import {useHistory} from 'react-router-dom';
import CONSTANTS from '../constants/constants';

const { Title , Text} = Typography;
const { TextArea } = Input;

const SearchTicket = (props) => {
  const {mitraReducer,mitraDispatch, setAlert,setSpin, userRole } = useContext(AppContext);

  const [mitraList, setMitraList] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [searchElement, setsearchElement] = useState('')
  const [managerMitraPhoneNumber, setManagetMitraPhoneNumber] = useState();
  const [sidebarContent, setSidebarContent] = useState({});
  const [filterSider, setfilterSider] = useState(false);
  const [ticketReOpenModal, setTicketReOpenModal] = useState(false);
  const [ticketReOpeningReason, setTicketReOpeningReason] = useState('');
  const [conversationHistory, setConversationHistory] = useState([]);
  const [chatModal, setChatModal] = useState(false);
  const history = useHistory();

  const searchApiCall =()=>{
    setDataSource([]);
    let data = {
      search:searchElement,
      mitraPhoneNumber: mitraReducer?.mitraInfo?.phoneNumber,
      mitraList: mitraList,
      managerMitraPhoneNumber: managerMitraPhoneNumber
    }
    if(searchElement !== ''){
        if(searchElement.length > 10 || searchElement.length < 10 ) {
			setAlert({
            display:true,
            text:'Please enter only the 10 digit mobile no. without any prefix (+91 or 0)',
            type:'error'
        });
    } else {
        const regex = new RegExp("^[0-9]{10}$");
			if(regex.test(searchElement)){
                setSpin({
                    loading: true, //false or true when actived
                    delay: null,
                    tip: 'Loading...'  //Loading copy here
                })
                searchIssues(data).then((response)=>{
                    setSpin({
                    loading: false, //false or true when actived
                    delay: null,
                    tip: 'Loading...'  //Loading copy here
                    })
                    if (response.data) {
                        let tableDataSource = [];
                        let name = '';
                        response.data.results.forEach((element, index) => {
                            const resolvedDate = moment(element.updated_at).format("DD-MM-YYYY hh:mm:ss");
                            const createdDate = moment(element.created_at).format("DD MMM YYYY");
                            if(element.custom_fields.cf_your_registered_phone_number.toString() === mitraReducer?.mitraInfo?.phoneNumber) {
                                name = mitraReducer?.mitraInfo?.name
                            } else {
                                mitraList.forEach(async mitra => {
                                    if(element.custom_fields.cf_your_registered_phone_number.toString() === mitra.phoneNumber) {
                                        name = mitra.name;
                                    }
                                });
                            }
                            if(element.status === 2) {
                                element.status_text = 'Open';
                            }
                            else if(element.status === 3) {
                                element.status_text = 'Pending';
                            }
                            else if(element.status === 4) {
                                element.status_text = 'Resolved';
                            }
                            else if(element.status === 5) {
                                element.status_text = 'Closed';
                            }
                            else if(element.status === 6) {
                                element.status_text = 'Waiting for your reply';
                            }
                            else if(element.status === 7) {
                                element.status_text = 'Raised to Client';
                            }
                            tableDataSource.push(
                                {
                                    key: element.id,
                                    ticketId: element.id,
                                    createdDate: createdDate,
                                    resolvedDate: element.status === 5 || element.status === 4 ? resolvedDate : null,
                                    resolutionSummary: element.custom_fields.cf_resolution_summary,
                                    description: element.description_text,
                                    phone: element.custom_fields.cf_your_registered_phone_number,
                                    clientName: element.custom_fields.cf_client_name,
                                    name: element.custom_fields.cf_candidate_name? element.custom_fields.cf_candidate_name : "",
                                    actionNeeded: element.custom_fields.cf_action_to_be_taken_by_mitra_leader,
                                    candidatePhoneNumber: element.custom_fields.cf_candidate_phone_number,
                                    status: element.status_text,
                                    issueType: element.custom_fields.cf_issue_type,
                                    requester_id: element.requester_id,
                                    responder_id: element.responder_id,
                                }
                            );
                        });
                        setDataSource(tableDataSource)
                    }
                }).catch((error) => {
                    setSpin({
                    loading: false,
                    delay: null,
                    tip: 'Loading...'
                    })
                    message.error(error.response.data.message);
                });
                } else {
                    setAlert({
                    display:true,
                    text:'Please enter only the 10 digit mobile no. without any prefix (+91 or 0)',
                    type:'error'
                    });
                }
        }
    }
  }

  const returnStatusTags = (record) => {
    if (record.status === 'Open') {
       return <Tag style={{background: '#85A5FF', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'var(--primary-font-family)',fontWeight:'700'}}>Open</Tag>
    }
    if (record.status === 'Closed') {
        return <Tag style={{background: '#BFBFBF', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'var(--primary-font-family)',fontWeight:'700'}}>Closed</Tag>
    }
    if (record.status === 'Pending') {
        return <Tag style={{background: '#BFBFBF', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'var(--primary-font-family)',fontWeight:'700'}}>Pending</Tag>
    }
    if (record.status === 'Resolved') {
        return <Tag style={{background: '#52C41A', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'var(--primary-font-family)',fontWeight:'700'}}>Resolved</Tag>
    }
    if (record.status === 'Waiting for your reply') {
        return <Tag style={{background: '#F74931', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'var(--primary-font-family)',fontWeight:'700'}}>Waiting for your reply</Tag>
    }
    if (record.status === 'Raised to Client') {
        return <Tag style={{background: '#FAAD14', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'var(--primary-font-family)',fontWeight:'700'}}>Raised to Client</Tag>
    }
};

const openReOpenModal = (record) => {
    setTicketReOpenModal(true);
    setSidebarContent(record);
};
const getConversationHistory = (ticket) => {
    getAllConversations(ticket)
    .then(response => {
        setConversationHistory(response.data);
    }).catch(err => console.log(err));
};
const openSider = (record) => {
    setSidebarContent(record);
    getConversationHistory(record);
    setfilterSider(true);
};

const openingChatModal = (record) => {
    setSidebarContent(record);
    getConversationHistory(record);
    setChatModal(true);
};

  const columns = [
    {
      title: 'Ticket No.',
      dataIndex: 'ticketId',
      key: 'ticketNumber',
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Candidate Phone Number',
      dataIndex: 'candidatePhoneNumber',
      key: 'phoneNumber',
      render: (text, record) => <div style={{display:'flex',flexDirection:'column'}}><Text>{record.name}</Text><Text>+91 {record.candidatePhoneNumber}</Text></div>,
    },
    {
        title: 'Client Name',
        dataIndex: 'clientName',
        key: 'clientName',
      },
    {
      title: 'Issue Type',
      dataIndex: 'issueType',
      key: 'issueType',
      render: (text) => <div style={{color: 'red'}}>{text}</div>,
    },
    {
      title: 'Issue Status',
      dataIndex: 'status',
      key: 'issueStatus',
      render: (text, record) => returnStatusTags(record)
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
      key: 'date',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => <div>
        {
            record.status === 'Closed' ? (
                <Button className='re-open-dash-button' onClick={()=>openReOpenModal(record)}>Re-Open</Button>
            ) : (
                <Button className='re-open-dash-button reply' onClick={()=>openingChatModal(record)}>Reply <SendOutlined /></Button>
            )
        }
      </div>,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      width: 100,
      render: (text, record) => <Button onClick={()=>openSider(record)} className="view-details-icon"  type="primary" ghost shape="circle" icon={<ArrowRightOutlined />} />,
    }
  ];

  useEffect(() => {
    searchApiCall();
  }, [searchElement])

    useEffect(() => {
        if(history.location?.state?.candidatePhoneNumber) {
            if(dataSource && dataSource.length > 0)
            {
                const data = dataSource.find(item => item.ticketId === history.location?.state?.ticketId);
                if(data) {
                    openSider(data);
                }
            }
        }
    }, [dataSource])

  useEffect( () => {
    getMitraTeamMembersList().then(response => {
        setMitraList(response.data.data);
    });

    if (!mitraReducer?.mitraInfo?.managerMitraID && mitraReducer?.mitraInfo?.role === CONSTANTS?.roles?.ADMIN) {
      setManagetMitraPhoneNumber(mitraReducer?.mitraInfo?.phoneNumber);
    } else {
      getMitraPhoneNumberFromId(mitraReducer?.mitraInfo?.managerMitraID).then((res) => {
        setManagetMitraPhoneNumber(res.data.mitraPhoneNumber);
      });
    }

    if(history.location?.state?.candidatePhoneNumber)
    {
        setsearchElement(history.location?.state?.candidatePhoneNumber);
    }
 }, [mitraReducer?.mitraInfo?.phoneNumber]);

 const closeReOpenModal = () => {
    setTicketReOpenModal(false);
    setTicketReOpeningReason('');
};

const onTicketReOpeningReasonChange = (event) => {
    setTicketReOpeningReason(event.target.value);
};

const reOpenCandidateTicket = () => {
    setfilterSider(false);
    if (!ticketReOpeningReason) {
        notification['error']({
            message: 'Please mention a reason to re-open this ticket !!'
          });
        return;
    } else {
        reOpenTicket(sidebarContent, ticketReOpeningReason)
        .then(response => {
            setTicketReOpenModal(false);
            setTicketReOpeningReason('');
            console.log(response);
            searchApiCall();
            notification['success']({
                message: 'Ticket Re-opened Successfully.'
              });
        }).catch(err=> {
            setTicketReOpenModal(false);
        setTicketReOpeningReason('');
            notification['error']({
                message: 'Issue in re-opening the ticket !!'
              });
        })
    }
};

const chatModalCancel = () => {
    setChatModal(false);
  };
  return<>
  <Modal
        className="help-reopen-modal-style"
        style={{ textAlign: 'left' }}
        title="Re-open Ticket"
        visible={ticketReOpenModal}
        onCancel={closeReOpenModal}
        footer={[
        <Button key="1" style={{borderColor:'#177CCD', color:'#177CCD', border:'#177CCD'}} className="ant-btn ant-btn-default" onClick={closeReOpenModal}>
            Cancel
        </Button>,
        <Button key="2" style={{background:'#177CCD',border:'#177CCD'}} className="ant-btn ant-btn-primary" onClick={reOpenCandidateTicket}>
        Re-open
        </Button>
        ]}
        >
            <div className='re-open-ticket-modal-container'>
                <Text>Reason for re-opening ticket (*)</Text>
                <TextArea
                    placeholder="Write your issue description here .."
                    // autoSize={{ minRows: 2, maxRows: 6 }}
                    rows={4}
                    onChange={onTicketReOpeningReasonChange}
                    value={ticketReOpeningReason}
                />
            </div>
    </Modal>
    <Chat
        freshdeskData={conversationHistory}
        ticket={sidebarContent}
        getConversation={getConversationHistory}
        chatModal={chatModal}
        chatModalCancel={chatModalCancel}
    ></Chat>
  <div className="SearchPage Home HelpComponent mainContentZ">
    <Anchor>
      <PageHeader
            ghost={false}
            className="search-page-header"
            title={"Search ticket by Candidate Phone Number"}
            onBack={() => window.history.back()}
      />
      <SearchComponent searchItem={setsearchElement} placeholder={"Search ticket by Candidates Phone Number" }/>
    </Anchor>
      {searchElement !== '' &&
      <>
          <Card style={{maxHeight:'60vh',overflow:'scroll'}}>
              {dataSource.length > 0 ? (
							// <Ticket
							// 	key={index}
							// 	ticket={ticket}
                            // />
                            <div className='table-section'>
                                <Table columns={columns} dataSource={dataSource} scroll={{}}></Table>
                            </div>
                            ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
              <span>
                Sorry! No result found for <Text strong>{searchElement}</Text>
              </span>
            }/>}
          </Card>
        </>
      }
    </div>
    {filterSider && 
				<SideSheetComponent
				sidername={<Text className='help-sider-name'>Issue Details</Text>}
                hideBottomButtons={true}
				onDismiss={() => {setfilterSider(false)}}>
					<div className='main-ticket-container'>
                        <div className='ticket-details'>
                            <div className='ticket-detailed-elements'>
                                <Text className='ticket-detail-fieldname'>Ticket Number</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.ticketId}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Candidate Name</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.name}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Candidate Number</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.candidatePhoneNumber}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Issue Type</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.issueType}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Status</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{returnStatusTags(sidebarContent)}</Text>
                            </div>
                            <div className='ticket-detailed-elements' style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around'}}>
                                <Text className='ticket-detail-fieldname'>Date & Time</Text>
                                <Text>:</Text>
                                <Text className='ticket-detail-fieldvalue'>{sidebarContent.createdDate}</Text>
                            </div>
                        </div>

                        <div className='description-section'>
                            <div className='description-title'>
                                Issue Description
                            </div>
                            <div className='description-content'>
                                {sidebarContent.description}
                            </div>
                        </div>

                        <div>
                            <Divider orientation="left">
                                <Text className='conversation-header'>Conversation History</Text>
                            </Divider>

                            <div className='help-sider-conversation-history-container'>
                                {
                                    conversationHistory.length > 0 ? (
                                        conversationHistory.map((item, index) => {
                                            return (
                                                <div key={item.id}>
                                                <div className='single-message-container'>
                                                    <div className='message-header'>
                                                        <div 
                                                        className={sidebarContent.requester_id === item.user_id ? 'message-header-icon mitra-leader': 'message-header-icon support'}
                                                        >
                                                            {sidebarContent.requester_id === item.user_id ? 'M':'S'}
                                                        </div>
                                                        <div className='message-header-text'>
                                                            <Text className='client-name-text'>
                                                            {sidebarContent.requester_id === item.user_id ? 'Mitra Leader':'Support Team'}
                                                            </Text>
                                                            <Text className='date-text'>
                                                                {moment(item.created_at).format("DD MMM YYYY hh:mm A")}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                    <div className='message-description'>
                                                        <Text>
                                                            <div dangerouslySetInnerHTML={{ __html: item.body }} />
                                                        </Text><br/>
                                                        {
                                                          item.attachments.length > 0 ? <Text>Attachments:</Text>: null  
                                                        }
                                                        {
                                                            item.attachments.length > 0 ? (
                                                                
                                                                item.attachments.map((attachment, index)=> {
                                                                    return (
                                                                        <div key={index}>
                                                                            <a 
                                                                            href={attachment.attachment_url} 
                                                                            style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}
                                                                            rel="noreferrer" 
                                                                            target="_blank">
                                                                                {attachment.name}
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                }
                                                                )
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>

                                                <Divider />
                                                </div>
                                            )
                                        })
                                            
                                        
                                    ) : <Empty description="No conversation history."/>
                                }
                                

                            </div>
                        </div>
                        

                        <div className='reply-button'>
                            {
                                sidebarContent.status === 'Closed' ? (
                                    <Button className='button-style re-open' onClick={()=> openReOpenModal(sidebarContent)}>Re-Open</Button>
                                ) : (
                                    <Button className='button-style'>Reply <SendOutlined/></Button>
                                )
                            }
                        </div>
                    </div>
				</SideSheetComponent>
			}
    </>
};

export default SearchTicket;
