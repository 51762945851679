import React, {useEffect, useReducer, useState, useRef, useContext} from "react";
import {useHistory} from "react-router-dom";
import {inValidateSession} from "../../service/loginService"
import "./../../css/accountPage.scss"
import useGAEventsTracker from '../../service/useGSEventsTracker';
// import useMoengage from '../../hooks/useMoengage';
import {AppContext} from "../../App";
import {PhoneFilled,IssuesCloseOutlined,PhoneOutlined,DollarCircleOutlined, SettingOutlined, SafetyCertificateOutlined, QuestionCircleOutlined, PercentageOutlined, HddOutlined, BookOutlined,BellOutlined, UsergroupAddOutlined,MessageOutlined} from '@ant-design/icons';
import {PageHeader, Typography, Tag} from 'antd';
import commonFunc from "../../utils/commonFunction";
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import * as leadsConvertedService from './../../service/leadsConvertedService';

const {Text, Link} = Typography;

const MorePage = () => {
    const {mitraReducer, mitraDispatch, setAlert, userRole} = useContext(AppContext);
    const history = useHistory();
    const AccountGAEventsTracker = useGAEventsTracker("Accounts")
    // const [createMoengageUser, trackEvent] = useMoengage();
    const insurance_manager = commonFunc.navigationCheck(mitraReducer?.mitraInfo?.role).insurance_manager;
    const insurance_team_member = commonFunc.navigationCheck(mitraReducer?.mitraInfo?.role).insurance_team_member;
    const show_incentives = commonFunc.navigationCheck(mitraReducer?.mitraInfo?.role).incentive;
    const client_update = commonFunc.navigationCheck(mitraReducer?.mitraInfo?.role).client_update;
    const training_hub = commonFunc.navigationCheck('').training_Hub;
    const convertedLeads = leadsConvertedService.checkMitraEligibilityForConvertedLeads(mitraReducer?.mitraInfo?.id);
    const help = commonFunc.navigationCheck('').help;
    const defaultValue = [
        {
            description: 'Check eligible leads..',
            name: 'AI Hot Leads',
            routes: 'samvadini-v2',
            img: <PhoneOutlined className="list-img"/>,
            newTitle: true,
        },
        {
            description: 'Get more FODs...',
            name: 'Get Hotline App',
            routes: 'download-hotline-app',
            img: <PhoneFilled className="list-img"/>,
            newTitle: true,
        },
        {
            img: <PercentageOutlined className="list-img" />,
            name: 'Incentives',
            description: 'Check All Incentives ...',
            routes: show_incentives ? 'incentives' : null,
            newTitle: true
        },
        {
            img: <HddOutlined className="list-img" />,
            name: 'Client Updates',
            description: 'Check client process details...',
            routes: client_update,
            newTitle: false
        },

        {
            img: <SafetyCertificateOutlined className="list-img" />,
            name: 'V Care',
            description: 'Insurance, VAS',
            routes: (insurance_team_member || insurance_manager),
            newTitle: true
        },
        {
            img: <MessageOutlined className="list-img" />,
            name: 'Rider Messages',
            description: 'Chat with riders',
            routes: 'selfserve',
            newTitle: true
        },
        {
            img: <BookOutlined className="list-img" />,
            name: 'Training',
            description: 'Check All Training...',
            routes: training_hub ? 'training-hub' : null,
            newTitle: true
        },
        {
            img: <DollarCircleOutlined className="list-img" />,
            name: 'Refer and Earn',
            description: 'Refer friends and earn...',
            routes: (!mitraReducer?.mitraInfo?.managerMitraID && mitraReducer?.mitraInfo?.role === CONSTANTS?.roles?.ADMIN) ? 'refer-and-earn' : null,
            newTitle: true
        },
        {
            img: <SettingOutlined className="list-img" />,
            name: 'Account',
            description: 'Training Sessions, Team Management, Lead Management,Refer Mitra, Help & Support',
            routes: 'account',
            newTitle: false
        },
        {
            img: <IssuesCloseOutlined className="list-img" />,
            name: 'Help',
            description: 'Check raised issues...',
            routes: help ? 'help' : null,
            newTitle: true
        },
        // {
        //     img: <QuestionCircleOutlined className="list-img" />,
        //     name: 'FAQ',
        //     description: 'Frequently Asked Questions',
        //     routes: 'faq',
        //     newTitle: false
        // },
        {
            img: <BellOutlined className="list-img" />,
            name: 'Inbox',
            description: 'Check your inbox and messages',
            routes: 'notification',
            newTitle: true
        },
        convertedLeads ? 
        {
            img: <UsergroupAddOutlined className="list-img" />,
            name: 'Vahan Converted Users',
            description: 'Check your vahan converted leads',
            routes: 'converted-leads',
            newTitle: true
        } : {}
    ];

    const trackMoengageEvent = (event, obj) => {
        trackEvent(event, obj || {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const handleAccountPages = (sidername) => {
        if (sidername === "terms-and-conditions") {
            trackMoengageEvent('terms_conditions_opened');
            return window.open("/terms-and-conditions")
        }
        // if (sidername?.routes === "faq") {
        //     trackMoengageEvent('faq_opened');
        //     return window.open(process.env.REACT_APP_FAQ_LINK);
        // } 
        if (sidername?.name === "Client Updates") {
            trackMoengageEvent('client_updates_viewed');
            return window.open(process.env.REACT_APP_CLIENT_UPDATES_LINK);
        }
        if (sidername?.routes === "incentives") {
            trackMoengageEvent('incentiveNavigationClick');
        }
        if (sidername?.name === "Help") {
            trackMoengageEvent('helpTabClick');
        }
        if (sidername?.name === "Training") {
            trackMoengageEvent('trainingHubTabClick');
        }
        if (sidername?.name === 'V Care') {
            trackEvent('VAS_tab_click', {CTA: Date.now()}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            if (mitraReducer?.mitraInfo?.role === 'admin' && process.env.REACT_APP_INSURANCE_SHOW_MANAGER == '1') {
                return window.open(process.env.REACT_APP_INSURANCE_MANAGER_LINK)
            } else if (mitraReducer?.mitraInfo?.role !== 'admin' && process.env.REACT_APP_INSURANCE_SHOW_TEAM_MEMBER == '1') {
                return window.open(process.env.REACT_APP_INSURANCE_TEAM_MEMBER_LINK)
            } else {
                return;
            }
        }
        history.push(`/${sidername.routes}`)
    }

    const handleLogout = () => {
        inValidateSession()
            .then((response) => {
                localStorage.clear()
                window.location.reload();
            })
            .catch((error) => {
                console.log('Error logging out: ', error);
            });
    };
    
    return (
        <React.Fragment>
            {/* <div className="navigation-container">
                <NavigationTab currentTab={4} />
            </div> */}
            <div className="MoreMenuPage AccountPage mainContentZ">
                <PageHeader
                    className="containerTop"
                    title="More"
                />
                <div className="scrollable-section">
                    {
                        defaultValue.map((item,index) => {
                            if(
                                item.name === 'AI Hot Leads' && 
                                !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.id) &&
                                !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.managerMitraID) && 
                                !CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.adminMitraId)
                            ) return null;

                            if (
								item.name  === 'Get Hotline App' &&
								!CONSTANTS.hotlineDownloadApplicableIds.includes(mitraReducer?.mitraInfo?.id) &&
								!CONSTANTS.hotlineDownloadApplicableIds.includes(mitraReducer?.mitraInfo?.managerMitraID) && 
                                !CONSTANTS.hotlineDownloadApplicableIds.includes(mitraReducer?.mitraInfo?.adminMitraId)
							) {
								return null;
							}
                            
                            if (item.routes) {
                                return <div key={index} id={`${item.name}_more_id`} className="containerMiddle">
                                    <div className="listview" onClick={() => handleAccountPages(item)}>
                                        {item.img}
                                        <div className="listview-name-des">
                                            <div className="name">
                                                {item.name}
                                                {/* {item.newTitle && 
						                            <Tag className="newTag-banner">New!</Tag>
                                                } */}
                                                {item.name === 'AI Hot Leads' || item.name  === 'Get Hotline App' ?
                                                    <img style={{ width: 'auto', height: '25px', marginLeft: '6px' }} src="/image/new-icon.gif" />
                                                    : null}
                                            </div>
                                            <div className="des">{item.description}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        })
                    }
                    <div className="containerBottom">
                        <Text id="tnC_more_id" onClick={() => handleAccountPages("terms-and-conditions")}>Terms & Conditions</Text>
                        <Text id="logout_more_id" onClick={() => handleLogout()} type="danger">Logout</Text>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default MorePage;
