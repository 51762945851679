import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Divider, Empty, Popover, notification } from 'antd';
import {EnvironmentOutlined, CopyOutlined, CalendarOutlined, PhoneOutlined, LoadingOutlined, CheckCircleOutlined} from '@ant-design/icons'
import moment from 'moment';
import { downloadInterestedLeads, getSessionDetails } from '../../service/SamvadiniApiService';
import { AppContext } from '../../App';

const LAYOUTS = {
    "NO_CONTENT_LAYOUT": "NO_CONTENT_LAYOUT",
    "LAYOUT_WITH_DATA": "LAYOUT_WITH_DATA"
}

function LatestProcessedCandidateCards({sessionDetailId}) {
    const [currentLayout, setCurrentLayout] = useState();
    const {mitraReducer, setSpin} = useContext(AppContext);

    const [detailedCandidateList, setDetailedCandidateList] = useState([]);
    const [isProcessingCompleted, setIsProcessingCompleted] = useState(false);
    const [isSamvadhiniPaused, setSamvadhiniPaused] = useState(false);
    const [requiredInterestedLeadCount, setRequiredInterestedLeadCount] = useState(0);
    const [completeSessionDetails, setCompleteSessionDetails] = useState({});

    let pollingInterval;

    const showLoader = (value, message=null) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: message || 'Downloading document, please wait..'  //Loading copy here
        })
    }

    const copyPhoneNumberFunc = (event, phoneNumber) => {
        event.stopPropagation();

        if(navigator?.clipboard) {
            navigator.clipboard.writeText(phoneNumber);
            notification.open({
                message: `${phoneNumber} copied to clipboard !`,
                // key,
                duration: 5,
                placement : 'topRight',
                icon: <CheckCircleOutlined className='green-positive' />,
            });
        } 
    }

    const clearPollingInterval = () => {
        if(pollingInterval) {
            clearInterval(pollingInterval);
        }
    }

    const getSessionDetailsInfo = async() => {
        try {
            const payload = {
                intentCallingSessionId: sessionDetailId
            }

            const response = await getSessionDetails(payload);

            if(response?.data?.status) {
                setRequiredInterestedLeadCount(response?.data?.expectedLeadCount);
                setIsProcessingCompleted(response?.data?.isSessionCompleted);
                setSamvadhiniPaused(response?.data?.samvadhiniPaused);
                setDetailedCandidateList([...response?.data?.interestedLeads]);

                setCompleteSessionDetails(response?.data);

                if(response?.data?.isSessionCompleted) {
                    clearPollingInterval();
                }
            } else {
                clearPollingInterval();
                setRequiredInterestedLeadCount(0);
                setIsProcessingCompleted(response?.data?.isSessionCompleted);
                setSamvadhiniPaused(false);
                setDetailedCandidateList([]);

                setCompleteSessionDetails(response?.data);
            }

        } catch(e) {
            clearPollingInterval();
            setRequiredInterestedLeadCount(0);
            setIsProcessingCompleted(false);
            setSamvadhiniPaused(false);
            setDetailedCandidateList([]);

            notification['error']({
                message: 'Something went wrong, please try again later.'
            })
        }

    }

    const getTime = (createdDate) => {
        if(!createdDate) return '';
        if (moment().diff(moment(createdDate).utc(),"days")){
            return `${moment().diff(moment(createdDate).utc().subtract(330, 'minutes'),"days")}d ago`;
        } else if(moment().diff(moment(createdDate).utc().subtract(330, 'minutes'),"hours")){
            return `${moment().diff(moment(createdDate).utc().subtract(330, 'minutes'),"hours")}hrs ago`;
        } else {
            return `${moment().diff(moment(createdDate).utc().subtract(330, 'minutes'),"minutes")}mins ago`;
        }
    }

    const getSessionCompletionTime = (sessionStartTime, sessionEndTime) => {
        if(!sessionEndTime || !sessionStartTime) return '';
        if (moment(sessionEndTime).diff(moment(sessionStartTime),"days")){
            return `${moment(sessionEndTime).diff(moment(sessionStartTime),"days")} days`;
        } else if(moment(sessionEndTime).diff(moment(sessionStartTime),"hours")){
            return `${moment(sessionEndTime).diff(moment(sessionStartTime),"hours")} hours`;
        } else {
            return `${moment(sessionEndTime).diff(moment(sessionStartTime),"minutes") || 1} minutes`;
        }
    }

    const startPolling = () => {
        getSessionDetailsInfo();
        pollingInterval = setInterval(() => {
            getSessionDetailsInfo();
        }, 30000);
    }

    useEffect(() => {
      if(sessionDetailId) {
        startPolling();
        setCurrentLayout(LAYOUTS.LAYOUT_WITH_DATA);
      } else {
        setCurrentLayout(LAYOUTS.NO_CONTENT_LAYOUT);
      }

      return () => {
        if(pollingInterval) {
          clearInterval(pollingInterval);
        }
      }
    }, [sessionDetailId])
    

    const downloadExcel = () => {
        showLoader(true);
        downloadInterestedLeads({intentCallingSessionId: sessionDetailId})
        .then(res=> {
            showLoader(false);
            if(res?.data?.downloadUrl) {
                window.open(res.data.downloadUrl, '_blank')
            } else {
                notification['error']({
                    message: 'File download response error, please try again later.'
                })
                showLoader(false);
            }
        }).catch(e=> {
            notification['error']({
                message: 'File download error, please try again later.'
            })
            showLoader(false);
        })
    };

  return (
    <>
        {
            currentLayout === LAYOUTS.LAYOUT_WITH_DATA ? (
                <div className='processed-candidates-header-container' style={{margin: '12px 24px 24px'}}>

                    {
                        detailedCandidateList.length ? (
                            <div className='primary-theme-container'>
                                Start Calling these candidates immediately, else they might not pick up your call
                            </div>
                        ): null
                    }
                    

                    <div className='blue-container'>
                        {
                            (!isProcessingCompleted && isSamvadhiniPaused) ? <>Your calling session has been paused. It will resume at 9 am Tomorrow </> :
                            !isProcessingCompleted ? <>Calling in Progress, candidates will start appearing in 4-5 minutes… <LoadingOutlined /></>:
                            isProcessingCompleted && (detailedCandidateList.length === 0) ? <>Sorry, no interested candidates found. Please upload new list and try again </>:
                            <>Processing finished! Found {detailedCandidateList.length} interested candidates in {getSessionCompletionTime(completeSessionDetails?.sessionCreatedAt, completeSessionDetails?.sessionCompletedAt)} !</>
                        }
                    </div>
                    <div className='header-and-download-section d-flex justify-content-between align-items-center' style={{marginTop: '12px'}}>
                        <div><b>{detailedCandidateList.length} Interested Candidate(s)</b></div>
                        
                        <Button danger style={{ margin: '5px 20px 5px auto', background: '#D34B18', color: '#FFF', fontWeight: 600}} onClick={() => downloadExcel()}>
                            Download File
                        </Button>
                        
                    </div>

                    {

                        detailedCandidateList.map((item,index) => {
                            return (
                                <div key={index} className='recent-processed-card-container' style={{marginTop: '16px'}}>
                                    <Card
                                    bodyStyle={{padding: '12px', border: index < 3 ? '1px solid orange':null}}
                                    >
                                        {
                                            index < 3 ? (<img src="/image/new-badge.png" className='new-item-badge' />): null
                                        }
                                        
                                        <div className='card-details-container'>

                                            <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                                <div className='d-flex align-items-center' style={{gap: '12px'}}>
                                                    <div style={{fontWeight: 600, fontSize: 15}}>{item.name}</div>

                                                    <div className='d-flex align-items-center' style={{gap: '4px'}}>
                                                        <img src={item?.allApplications?.[item?.allApplications?.length - 1]?.companyLogo} style={{width: '60px'}} />

                                                        {
                                                            item?.allApplications?.length > 1 ? (
                                                                <Popover
                                                                content={
                                                                    item?.allApplications?.map((client, index) => (
                                                                        <div key={index} className='more-clients'>
                                                                            {
                                                                                index!== item?.allApplications?.length-1 ? (
                                                                                    <img src={item?.allApplications?.[index]?.companyLogo} style={{width: '60px'}} />
                                                                                ): null
                                                                            }
                                                                            
                                                                        </div>
                                                                    ))
                                                                }
                                                                placement="rightBottom"
                                                                title={<b>Clients</b>} 
                                                                trigger="hover"
                                                                >
                                                                  +{item?.allApplications?.length-1} clients   
                                                                </Popover>
                                                            ): null
                                                        }
                                                        
                                                    </div>

                                                </div>
                                                {/* <img src='https://uploads-a.vahan.co/fileUploder/2022-06-22/dfXA89-uber.png' style={{width: '60px'}} /> */}
                                                
                                                {
                                                    item?.referredBy ? (
                                                        <div className='d-flex align-items-center' style={{gap: '4px'}}>
                                                            Referred by <div className='orange-dot' style={{margin: '0px 4px'}}></div> {mitraReducer?.mitraInfo?.id === item?.referralSource ? 'You': item?.referredBy}
                                                        </div>
                                                    ): null
                                                }
                                                
                                                <div className='d-flex align-items-center' style={{gap: '4px'}}>
                                                    <EnvironmentOutlined /> {item?.latestReferredLocation}
                                                </div>
                                            </div>

                                            <Divider style={{marginTop: '12px', marginBottom: '12px'}}/>

                                            <div className='card-body-desktop-view'>
                                                <div className='content-body d-flex flex-column align-items-start'>
                                                    <div className='d-flex align-items-center' style={{gap: '6px'}}><CalendarOutlined /> Referred on {item?.latestClientReferredDate ? moment(item?.latestClientReferredDate).format('Do MMMM YYYY'): ''}</div>
                                                </div>
                                                <div className='d-flex' style={{gap: '8px'}}> <div>Called by Samvaadini</div> <div className='orange-dot' style={{marginTop: '8px'}}></div> <div>{getTime(item?.calledAt)}</div></div>
                                                <Button style={{display: 'flex'}} className='contact-button' onClick={(e) => copyPhoneNumberFunc(e, item?.phoneNumber)}
                                                    icon={<CopyOutlined />} type="primary" ghost>
                                                    {item?.phoneNumber}
                                                </Button>
                                            </div>

                                            <div className='card-body-mobile-view'>
                                                <div className='content-body d-flex align-items-start justify-content-between flex-wrap' style={{marginTop: '10px'}}>
                                                    <div className='d-flex align-items-center' style={{gap: '6px'}}><CalendarOutlined /> Referred on {item?.latestClientReferredDate ? moment(item?.latestClientReferredDate).format('Do MMMM YYYY'): ''}</div>
                                                    <a
                                                    type='primary'
                                                    href={`tel: +91-${item?.phoneNumber}`}
                                                    onClick={(e) => copyPhoneNumberFunc(e, item?.phoneNumber)} 
                                                    ghost>
                                                        <Button style={{display: 'flex'}} className='contact-button'
                                                            icon={<PhoneOutlined />} type="primary" ghost>
                                                            {item?.phoneNumber}
                                                        </Button>
                                                    </a>
                                                </div>
                                                <div className='d-flex' style={{gap: '8px'}}> <div>Called by Samvaadini</div> <div className='orange-dot' style={{marginTop: '8px'}}></div> <div>{getTime(item?.calledAt)}</div></div>
                                                
                                            </div>
                                            
                                        </div>
                                    </Card>
                                </div>
                            )})
                    }

                </div>
            ):
            currentLayout === LAYOUTS.NO_CONTENT_LAYOUT ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                    <span>
                      No data
                    </span>
                  }/>
            ): null
        }
    </>
  )
}

export default LatestProcessedCandidateCards