import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons'
import './ClientDetailsContainerStyle.scss'
import '../../../style/JobsPageContainerStyle.scss'
import { trackEvent } from '../../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../../constants/constants';


const CDAllLocalitiesListing = ({ localityData = {
    "locality": "",
    "totalOpenings": "",
    "partTime": "",
    "fullTime": "",
    "onboardingFee": null,
    "upfrontFee": null,
    "vehicle": null,
    "joiningBonusAvailable": false
},
    localityInd, selectedCity, selectedJobDetails }) => {
    const history = useHistory()

    useEffect(() => {
    }, [])

    const onReferCandidateClicked = () => {
        trackEvent('clicked_refer_candidate_from_jobs', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        let locality = localityData.locality
        let client = selectedJobDetails && selectedJobDetails.companyName ? selectedJobDetails.companyName : ""
        if (localityData.locality.includes('&')) {
            locality = locality.replace('&', 'and')
        }
        if (client) {
            client = client.replace(/_3PL/g, ' With Mitra').replace(/_Salary/g, ' - Salary')
        }
        const prams = `navigateTo=addSingleCandidate${selectedCity ? `&city=${selectedCity}` : ''}${client ? `&client=${client}` : ''}${locality ? `&locality=${locality}` : ''}`;
        history.push('/add-single-candidate?' + prams);
    }

    return (
        <div className="cd-all-localities-listing-parent">
            <div className='cd-al-locality-openings d-flex align-items-center justify-content-between'>
                <div className='cd-al-locality d-flex align-items-center'>
                    <EnvironmentOutlined className='cd-al-locality-icon' />
                    <span className='cd-al-locality-txt'>
                        {localityData.locality}
                    </span>
                </div>

                <div className='cd-al-openings d-flex align-items-center justify-content-end'>
                    <span className='cd-al-openings-txt d-flex'>
                        {localityData.totalOpenings} jobs <span className='show-only-desktop'>&nbsp;available</span>
                    </span>

                    {
                        localityData.partTime ?
                            <div className='d-flex'>
                                <span className='cd-al-vertical-divider' />

                                <span className='cd-al-part-time-openings-txt'>{localityData.partTime} part time</span>
                            </div>
                            :
                            null
                    }
                </div>
            </div>

            <div className='cd-al-onboarding-upfront-fee d-flex align-items-center'>
                <div className='cd-al-vehicle-fees'>
                    {
                        localityData.vehicle ?
                            <span className='cd-al-vehicle-required-txt'>
                                {localityData.vehicle}
                            </span> : null
                    }

                    {
                        localityData.vehicle && (localityData.onboardingFee || localityData.upfrontFee) ?
                            <span className='cd-al-vehicle-required-txt show-only-desktop'>
                                &nbsp;•&nbsp;
                            </span>
                            :
                            null
                    }

                    <div className='cd-al-fees d-flex align-items-center'>
                        {
                            localityData.onboardingFee ?
                                <div className='d-flex'>
                                    <span className='cd-al-onboarding-fee-label cd-al-fee-label'>
                                        Onboarding Fees:
                                    </span>
                                    <span className='cd-al-onboarding-fee-txt'>
                                        &nbsp; {localityData.onboardingFee !== "NA" ? "₹" : ""} {localityData.onboardingFee}
                                    </span>
                                </div>
                                : null
                        }

                        {localityData.upfrontFee ?
                            <span className='cd-al-vertical-divider' />
                            :
                            null
                        }


                        {
                            localityData.upfrontFee ?
                                <div className='d-flex'>
                                    <span className='cd-al-upfront-fee-label cd-al-fee-label'>
                                        Upfront:
                                    </span>
                                    <span className='cd-al-upfront-fee-txt'>
                                        &nbsp;{localityData.upfrontFee !== "NA" ? "₹" : ""} {localityData.upfrontFee}
                                    </span>
                                </div>
                                : null
                        }

                    </div>
                </div>
            </div>

            <div className='cd-al-joining-bonus-btn d-flex justify-content-between'>
                {
                    localityData.joiningbonusconstruct ?
                        <div className='cd-al-joining-bonus d-flex align-items-center'>
                            ₹{localityData.joiningbonusconstruct} Joining Bonus
                        </div>
                        :
                        <div></div>
                }
                <div className='cd-al-refer-btn-container d-flex justify-content-end'>
                    <Button className='cd-al-refer-btn d-flex align-items-center jp-cp justify-content-center' onClick={() => onReferCandidateClicked()}>
                        <div className='cd-al-refer-btn-txt'>
                            Refer Lead
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CDAllLocalitiesListing; 